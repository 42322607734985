* {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  overflow: clip;
}

html,
body {
  overscroll-behavior: none;
}

.w-richtext > :first-child {
  margin-top: 0;
}

.w-richtext > :last-child,
.w-richtext ol li:last-child,
.w-richtext ul li:last-child {
  margin-bottom: 0;
}

p,
label {
  margin-bottom: 0;
}

p {
  line-height: inherit;
}

a,
.w-tab-link,
.w-nav-link,
.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link,
.w-nav-brand {
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
}

[class*=bg0-0] {
  background: var(--color--0-0);
}

[class*=bc0-0] {
  border-color: var(--color--0-0);
}

[class*=oc0-0] {
  outline-color: var(--color--0-0);
}

[class*=fc0-0] {
  color: var(--color--0-0);
}

[class*=sc0-0] {
  -webkit-text-stroke-color: var(--color--0-0);
}

[class*=bg0-1] {
  background: var(--color--0-1);
}

[class*=bc0-1] {
  border-color: var(--color--0-1);
}

[class*=oc0-1] {
  outline-color: var(--color--0-1);
}

[class*=fc0-1] {
  color: var(--color--0-1);
}

[class*=sc0-1] {
  -webkit-text-stroke-color: var(--color--0-1);
}

[class*=bg0-2] {
  background: var(--color--0-2);
}

[class*=bc0-2] {
  border-color: var(--color--0-2);
}

[class*=oc0-2] {
  outline-color: var(--color--0-2);
}

[class*=fc0-2] {
  color: var(--color--0-2);
}

[class*=sc0-2] {
  -webkit-text-stroke-color: var(--color--0-2);
}

[class*=bg0-3] {
  background: var(--color--0-3);
}

[class*=bc0-3] {
  border-color: var(--color--0-3);
}

[class*=oc0-3] {
  outline-color: var(--color--0-3);
}

[class*=fc0-3] {
  color: var(--color--0-3);
}

[class*=sc0-3] {
  -webkit-text-stroke-color: var(--color--0-3);
}

[class*=bg0-4] {
  background: var(--color--0-4);
}

[class*=bc0-4] {
  border-color: var(--color--0-4);
}

[class*=oc0-4] {
  outline-color: var(--color--0-4);
}

[class*=fc0-4] {
  color: var(--color--0-4);
}

[class*=sc0-4] {
  -webkit-text-stroke-color: var(--color--0-4);
}

[class*=bg0-5] {
  background: var(--color--0-5);
}

[class*=bc0-5] {
  border-color: var(--color--0-5);
}

[class*=oc0-5] {
  outline-color: var(--color--0-5);
}

[class*=fc0-5] {
  color: var(--color--0-5);
}

[class*=sc0-5] {
  -webkit-text-stroke-color: var(--color--0-5);
}

[class*=bg0-6] {
  background: var(--color--0-6);
}

[class*=bc0-6] {
  border-color: var(--color--0-6);
}

[class*=oc0-6] {
  outline-color: var(--color--0-6);
}

[class*=fc0-6] {
  color: var(--color--0-6);
}

[class*=sc0-6] {
  -webkit-text-stroke-color: var(--color--0-6);
}

[class*=bg0-7] {
  background: var(--color--0-7);
}

[class*=bc0-7] {
  border-color: var(--color--0-7);
}

[class*=oc0-7] {
  outline-color: var(--color--0-7);
}

[class*=fc0-7] {
  color: var(--color--0-7);
}

[class*=sc0-7] {
  -webkit-text-stroke-color: var(--color--0-7);
}

[class*=bg0-8] {
  background: var(--color--0-8);
}

[class*=bc0-8] {
  border-color: var(--color--0-8);
}

[class*=oc0-8] {
  outline-color: var(--color--0-8);
}

[class*=fc0-8] {
  color: var(--color--0-8);
}

[class*=sc0-8] {
  -webkit-text-stroke-color: var(--color--0-8);
}

[class*=bg0-9] {
  background: var(--color--0-9);
}

[class*=bc0-9] {
  border-color: var(--color--0-9);
}

[class*=oc0-9] {
  outline-color: var(--color--0-9);
}

[class*=fc0-9] {
  color: var(--color--0-9);
}

[class*=sc0-9] {
  -webkit-text-stroke-color: var(--color--0-9);
}

[class*=bg1-0] {
  background: var(--color--1-0);
}

[class*=bc1-0] {
  border-color: var(--color--1-0);
}

[class*=oc1-0] {
  outline-color: var(--color--1-0);
}

[class*=fc1-0] {
  color: var(--color--1-0);
}

[class*=sc1-0] {
  -webkit-text-stroke-color: var(--color--1-0);
}

[class*=bg1-1] {
  background: var(--color--1-1);
}

[class*=bc1-1] {
  border-color: var(--color--1-1);
}

[class*=oc1-1] {
  outline-color: var(--color--1-1);
}

[class*=fc1-1] {
  color: var(--color--1-1);
}

[class*=sc1-1] {
  -webkit-text-stroke-color: var(--color--1-1);
}

[class*=bg1-2] {
  background: var(--color--1-2);
}

[class*=bc1-2] {
  border-color: var(--color--1-2);
}

[class*=oc1-2] {
  outline-color: var(--color--1-2);
}

[class*=fc1-2] {
  color: var(--color--1-2);
}

[class*=sc1-2] {
  -webkit-text-stroke-color: var(--color--1-2);
}

[class*=bg1-3] {
  background: var(--color--1-3);
}

[class*=bc1-3] {
  border-color: var(--color--1-3);
}

[class*=oc1-3] {
  outline-color: var(--color--1-3);
}

[class*=fc1-3] {
  color: var(--color--1-3);
}

[class*=sc1-3] {
  -webkit-text-stroke-color: var(--color--1-3);
}

[class*=bg1-4] {
  background: var(--color--1-4);
}

[class*=bc1-4] {
  border-color: var(--color--1-4);
}

[class*=oc1-4] {
  outline-color: var(--color--1-4);
}

[class*=fc1-4] {
  color: var(--color--1-4);
}

[class*=sc1-4] {
  -webkit-text-stroke-color: var(--color--1-4);
}

[class*=bg1-5] {
  background: var(--color--1-5);
}

[class*=bc1-5] {
  border-color: var(--color--1-5);
}

[class*=oc1-5] {
  outline-color: var(--color--1-5);
}

[class*=fc1-5] {
  color: var(--color--1-5);
}

[class*=sc1-5] {
  -webkit-text-stroke-color: var(--color--1-5);
}

[class*=bg1-6] {
  background: var(--color--1-6);
}

[class*=bc1-6] {
  border-color: var(--color--1-6);
}

[class*=oc1-6] {
  outline-color: var(--color--1-6);
}

[class*=fc1-6] {
  color: var(--color--1-6);
}

[class*=sc1-6] {
  -webkit-text-stroke-color: var(--color--1-6);
}

[class*=bg1-7] {
  background: var(--color--1-7);
}

[class*=bc1-7] {
  border-color: var(--color--1-7);
}

[class*=oc1-7] {
  outline-color: var(--color--1-7);
}

[class*=fc1-7] {
  color: var(--color--1-7);
}

[class*=sc1-7] {
  -webkit-text-stroke-color: var(--color--1-7);
}

[class*=bg1-8] {
  background: var(--color--1-8);
}

[class*=bc1-8] {
  border-color: var(--color--1-8);
}

[class*=oc1-8] {
  outline-color: var(--color--1-8);
}

[class*=fc1-8] {
  color: var(--color--1-8);
}

[class*=sc1-8] {
  -webkit-text-stroke-color: var(--color--1-8);
}

[class*=bg1-9] {
  background: var(--color--1-9);
}

[class*=bc1-9] {
  border-color: var(--color--1-9);
}

[class*=oc1-9] {
  outline-color: var(--color--1-9);
}

[class*=fc1-9] {
  color: var(--color--1-9);
}

[class*=sc1-9] {
  -webkit-text-stroke-color: var(--color--1-9);
}

[class*=bg2-0] {
  background: var(--color--2-0);
}

[class*=bc2-0] {
  border-color: var(--color--2-0);
}

[class*=oc2-0] {
  outline-color: var(--color--2-0);
}

[class*=fc2-0] {
  color: var(--color--2-0);
}

[class*=sc2-0] {
  -webkit-text-stroke-color: var(--color--2-0);
}

[class*=bg2-1] {
  background: var(--color--2-1);
}

[class*=bc2-1] {
  border-color: var(--color--2-1);
}

[class*=oc2-1] {
  outline-color: var(--color--2-1);
}

[class*=fc2-1] {
  color: var(--color--2-1);
}

[class*=sc2-1] {
  -webkit-text-stroke-color: var(--color--2-1);
}

[class*=bg2-2] {
  background: var(--color--2-2);
}

[class*=bc2-2] {
  border-color: var(--color--2-2);
}

[class*=oc2-2] {
  outline-color: var(--color--2-2);
}

[class*=fc2-2] {
  color: var(--color--2-2);
}

[class*=sc2-2] {
  -webkit-text-stroke-color: var(--color--2-2);
}

[class*=bg2-3] {
  background: var(--color--2-3);
}

[class*=bc2-3] {
  border-color: var(--color--2-3);
}

[class*=oc2-3] {
  outline-color: var(--color--2-3);
}

[class*=fc2-3] {
  color: var(--color--2-3);
}

[class*=sc2-3] {
  -webkit-text-stroke-color: var(--color--2-3);
}

[class*=bg2-4] {
  background: var(--color--2-4);
}

[class*=bc2-4] {
  border-color: var(--color--2-4);
}

[class*=oc2-4] {
  outline-color: var(--color--2-4);
}

[class*=fc2-4] {
  color: var(--color--2-4);
}

[class*=sc2-4] {
  -webkit-text-stroke-color: var(--color--2-4);
}

[class*=bg2-5] {
  background: var(--color--2-5);
}

[class*=bc2-5] {
  border-color: var(--color--2-5);
}

[class*=oc2-5] {
  outline-color: var(--color--2-5);
}

[class*=fc2-5] {
  color: var(--color--2-5);
}

[class*=sc2-5] {
  -webkit-text-stroke-color: var(--color--2-5);
}

[class*=bg2-6] {
  background: var(--color--2-6);
}

[class*=bc2-6] {
  border-color: var(--color--2-6);
}

[class*=oc2-6] {
  outline-color: var(--color--2-6);
}

[class*=fc2-6] {
  color: var(--color--2-6);
}

[class*=sc2-6] {
  -webkit-text-stroke-color: var(--color--2-6);
}

[class*=bg2-7] {
  background: var(--color--2-7);
}

[class*=bc2-7] {
  border-color: var(--color--2-7);
}

[class*=oc2-7] {
  outline-color: var(--color--2-7);
}

[class*=fc2-7] {
  color: var(--color--2-7);
}

[class*=sc2-7] {
  -webkit-text-stroke-color: var(--color--2-7);
}

[class*=bg2-8] {
  background: var(--color--2-8);
}

[class*=bc2-8] {
  border-color: var(--color--2-8);
}

[class*=oc2-8] {
  outline-color: var(--color--2-8);
}

[class*=fc2-8] {
  color: var(--color--2-8);
}

[class*=sc2-8] {
  -webkit-text-stroke-color: var(--color--2-8);
}

[class*=bg2-9] {
  background: var(--color--2-9);
}

[class*=bc2-9] {
  border-color: var(--color--2-9);
}

[class*=oc2-9] {
  outline-color: var(--color--2-9);
}

[class*=fc2-9] {
  color: var(--color--2-9);
}

[class*=sc2-9] {
  -webkit-text-stroke-color: var(--color--2-9);
}

[class*=bg3-0] {
  background: var(--color--3-0);
}

[class*=bc3-0] {
  border-color: var(--color--3-0);
}

[class*=oc3-0] {
  outline-color: var(--color--3-0);
}

[class*=fc3-0] {
  color: var(--color--3-0);
}

[class*=sc3-0] {
  -webkit-text-stroke-color: var(--color--3-0);
}

[class*=bg3-1] {
  background: var(--color--3-1);
}

[class*=bc3-1] {
  border-color: var(--color--3-1);
}

[class*=oc3-1] {
  outline-color: var(--color--3-1);
}

[class*=fc3-1] {
  color: var(--color--3-1);
}

[class*=sc3-1] {
  -webkit-text-stroke-color: var(--color--3-1);
}

[class*=bg3-2] {
  background: var(--color--3-2);
}

[class*=bc3-2] {
  border-color: var(--color--3-2);
}

[class*=oc3-2] {
  outline-color: var(--color--3-2);
}

[class*=fc3-2] {
  color: var(--color--3-2);
}

[class*=sc3-2] {
  -webkit-text-stroke-color: var(--color--3-2);
}

[class*=bg3-3] {
  background: var(--color--3-3);
}

[class*=bc3-3] {
  border-color: var(--color--3-3);
}

[class*=oc3-3] {
  outline-color: var(--color--3-3);
}

[class*=fc3-3] {
  color: var(--color--3-3);
}

[class*=sc3-3] {
  -webkit-text-stroke-color: var(--color--3-3);
}

[class*=bg3-4] {
  background: var(--color--3-4);
}

[class*=bc3-4] {
  border-color: var(--color--3-4);
}

[class*=oc3-4] {
  outline-color: var(--color--3-4);
}

[class*=fc3-4] {
  color: var(--color--3-4);
}

[class*=sc3-4] {
  -webkit-text-stroke-color: var(--color--3-4);
}

[class*=bg3-5] {
  background: var(--color--3-5);
}

[class*=bc3-5] {
  border-color: var(--color--3-5);
}

[class*=oc3-5] {
  outline-color: var(--color--3-5);
}

[class*=fc3-5] {
  color: var(--color--3-5);
}

[class*=sc3-5] {
  -webkit-text-stroke-color: var(--color--3-5);
}

[class*=bg3-6] {
  background: var(--color--3-6);
}

[class*=bc3-6] {
  border-color: var(--color--3-6);
}

[class*=oc3-6] {
  outline-color: var(--color--3-6);
}

[class*=fc3-6] {
  color: var(--color--3-6);
}

[class*=sc3-6] {
  -webkit-text-stroke-color: var(--color--3-6);
}

[class*=bg3-7] {
  background: var(--color--3-7);
}

[class*=bc3-7] {
  border-color: var(--color--3-7);
}

[class*=oc3-7] {
  outline-color: var(--color--3-7);
}

[class*=fc3-7] {
  color: var(--color--3-7);
}

[class*=sc3-7] {
  -webkit-text-stroke-color: var(--color--3-7);
}

[class*=bg3-8] {
  background: var(--color--3-8);
}

[class*=bc3-8] {
  border-color: var(--color--3-8);
}

[class*=oc3-8] {
  outline-color: var(--color--3-8);
}

[class*=fc3-8] {
  color: var(--color--3-8);
}

[class*=sc3-8] {
  -webkit-text-stroke-color: var(--color--3-8);
}

[class*=bg3-9] {
  background: var(--color--3-9);
}

[class*=bc3-9] {
  border-color: var(--color--3-9);
}

[class*=oc3-9] {
  outline-color: var(--color--3-9);
}

[class*=fc3-9] {
  color: var(--color--3-9);
}

[class*=sc3-9] {
  -webkit-text-stroke-color: var(--color--3-9);
}

[class*=bg4-0] {
  background: var(--color--4-0);
}

[class*=bc4-0] {
  border-color: var(--color--4-0);
}

[class*=oc4-0] {
  outline-color: var(--color--4-0);
}

[class*=fc4-0] {
  color: var(--color--4-0);
}

[class*=sc4-0] {
  -webkit-text-stroke-color: var(--color--4-0);
}

[class*=bg4-1] {
  background: var(--color--4-1);
}

[class*=bc4-1] {
  border-color: var(--color--4-1);
}

[class*=oc4-1] {
  outline-color: var(--color--4-1);
}

[class*=fc4-1] {
  color: var(--color--4-1);
}

[class*=sc4-1] {
  -webkit-text-stroke-color: var(--color--4-1);
}

[class*=bg4-2] {
  background: var(--color--4-2);
}

[class*=bc4-2] {
  border-color: var(--color--4-2);
}

[class*=oc4-2] {
  outline-color: var(--color--4-2);
}

[class*=fc4-2] {
  color: var(--color--4-2);
}

[class*=sc4-2] {
  -webkit-text-stroke-color: var(--color--4-2);
}

[class*=bg4-3] {
  background: var(--color--4-3);
}

[class*=bc4-3] {
  border-color: var(--color--4-3);
}

[class*=oc4-3] {
  outline-color: var(--color--4-3);
}

[class*=fc4-3] {
  color: var(--color--4-3);
}

[class*=sc4-3] {
  -webkit-text-stroke-color: var(--color--4-3);
}

[class*=bg4-4] {
  background: var(--color--4-4);
}

[class*=bc4-4] {
  border-color: var(--color--4-4);
}

[class*=oc4-4] {
  outline-color: var(--color--4-4);
}

[class*=fc4-4] {
  color: var(--color--4-4);
}

[class*=sc4-4] {
  -webkit-text-stroke-color: var(--color--4-4);
}

[class*=bg4-5] {
  background: var(--color--4-5);
}

[class*=bc4-5] {
  border-color: var(--color--4-5);
}

[class*=oc4-5] {
  outline-color: var(--color--4-5);
}

[class*=fc4-5] {
  color: var(--color--4-5);
}

[class*=sc4-5] {
  -webkit-text-stroke-color: var(--color--4-5);
}

[class*=bg4-6] {
  background: var(--color--4-6);
}

[class*=bc4-6] {
  border-color: var(--color--4-6);
}

[class*=oc4-6] {
  outline-color: var(--color--4-6);
}

[class*=fc4-6] {
  color: var(--color--4-6);
}

[class*=sc4-6] {
  -webkit-text-stroke-color: var(--color--4-6);
}

[class*=bg4-7] {
  background: var(--color--4-7);
}

[class*=bc4-7] {
  border-color: var(--color--4-7);
}

[class*=oc4-7] {
  outline-color: var(--color--4-7);
}

[class*=fc4-7] {
  color: var(--color--4-7);
}

[class*=sc4-7] {
  -webkit-text-stroke-color: var(--color--4-7);
}

[class*=bg4-8] {
  background: var(--color--4-8);
}

[class*=bc4-8] {
  border-color: var(--color--4-8);
}

[class*=oc4-8] {
  outline-color: var(--color--4-8);
}

[class*=fc4-8] {
  color: var(--color--4-8);
}

[class*=sc4-8] {
  -webkit-text-stroke-color: var(--color--4-8);
}

[class*=bg4-9] {
  background: var(--color--4-9);
}

[class*=bc4-9] {
  border-color: var(--color--4-9);
}

[class*=oc4-9] {
  outline-color: var(--color--4-9);
}

[class*=fc4-9] {
  color: var(--color--4-9);
}

[class*=sc4-9] {
  -webkit-text-stroke-color: var(--color--4-9);
}

[class*=bg5-0] {
  background: var(--color--5-0);
}

[class*=bc5-0] {
  border-color: var(--color--5-0);
}

[class*=oc5-0] {
  outline-color: var(--color--5-0);
}

[class*=fc5-0] {
  color: var(--color--5-0);
}

[class*=sc5-0] {
  -webkit-text-stroke-color: var(--color--5-0);
}

[class*=bg5-1] {
  background: var(--color--5-1);
}

[class*=bc5-1] {
  border-color: var(--color--5-1);
}

[class*=oc5-1] {
  outline-color: var(--color--5-1);
}

[class*=fc5-1] {
  color: var(--color--5-1);
}

[class*=sc5-1] {
  -webkit-text-stroke-color: var(--color--5-1);
}

[class*=bg5-2] {
  background: var(--color--5-2);
}

[class*=bc5-2] {
  border-color: var(--color--5-2);
}

[class*=oc5-2] {
  outline-color: var(--color--5-2);
}

[class*=fc5-2] {
  color: var(--color--5-2);
}

[class*=sc5-2] {
  -webkit-text-stroke-color: var(--color--5-2);
}

[class*=bg5-3] {
  background: var(--color--5-3);
}

[class*=bc5-3] {
  border-color: var(--color--5-3);
}

[class*=oc5-3] {
  outline-color: var(--color--5-3);
}

[class*=fc5-3] {
  color: var(--color--5-3);
}

[class*=sc5-3] {
  -webkit-text-stroke-color: var(--color--5-3);
}

[class*=bg5-4] {
  background: var(--color--5-4);
}

[class*=bc5-4] {
  border-color: var(--color--5-4);
}

[class*=oc5-4] {
  outline-color: var(--color--5-4);
}

[class*=fc5-4] {
  color: var(--color--5-4);
}

[class*=sc5-4] {
  -webkit-text-stroke-color: var(--color--5-4);
}

[class*=bg5-5] {
  background: var(--color--5-5);
}

[class*=bc5-5] {
  border-color: var(--color--5-5);
}

[class*=oc5-5] {
  outline-color: var(--color--5-5);
}

[class*=fc5-5] {
  color: var(--color--5-5);
}

[class*=sc5-5] {
  -webkit-text-stroke-color: var(--color--5-5);
}

[class*=bg5-6] {
  background: var(--color--5-6);
}

[class*=bc5-6] {
  border-color: var(--color--5-6);
}

[class*=oc5-6] {
  outline-color: var(--color--5-6);
}

[class*=fc5-6] {
  color: var(--color--5-6);
}

[class*=sc5-6] {
  -webkit-text-stroke-color: var(--color--5-6);
}

[class*=bg5-7] {
  background: var(--color--5-7);
}

[class*=bc5-7] {
  border-color: var(--color--5-7);
}

[class*=oc5-7] {
  outline-color: var(--color--5-7);
}

[class*=fc5-7] {
  color: var(--color--5-7);
}

[class*=sc5-7] {
  -webkit-text-stroke-color: var(--color--5-7);
}

[class*=bg5-8] {
  background: var(--color--5-8);
}

[class*=bc5-8] {
  border-color: var(--color--5-8);
}

[class*=oc5-8] {
  outline-color: var(--color--5-8);
}

[class*=fc5-8] {
  color: var(--color--5-8);
}

[class*=sc5-8] {
  -webkit-text-stroke-color: var(--color--5-8);
}

[class*=bg5-9] {
  background: var(--color--5-9);
}

[class*=bc5-9] {
  border-color: var(--color--5-9);
}

[class*=oc5-9] {
  outline-color: var(--color--5-9);
}

[class*=fc5-9] {
  color: var(--color--5-9);
}

[class*=sc5-9] {
  -webkit-text-stroke-color: var(--color--5-9);
}

[class*=bg6-0] {
  background: var(--color--6-0);
}

[class*=bc6-0] {
  border-color: var(--color--6-0);
}

[class*=oc6-0] {
  outline-color: var(--color--6-0);
}

[class*=fc6-0] {
  color: var(--color--6-0);
}

[class*=sc6-0] {
  -webkit-text-stroke-color: var(--color--6-0);
}

[class*=bg6-1] {
  background: var(--color--6-1);
}

[class*=bc6-1] {
  border-color: var(--color--6-1);
}

[class*=oc6-1] {
  outline-color: var(--color--6-1);
}

[class*=fc6-1] {
  color: var(--color--6-1);
}

[class*=sc6-1] {
  -webkit-text-stroke-color: var(--color--6-1);
}

[class*=bg6-2] {
  background: var(--color--6-2);
}

[class*=bc6-2] {
  border-color: var(--color--6-2);
}

[class*=oc6-2] {
  outline-color: var(--color--6-2);
}

[class*=fc6-2] {
  color: var(--color--6-2);
}

[class*=sc6-2] {
  -webkit-text-stroke-color: var(--color--6-2);
}

[class*=bg6-3] {
  background: var(--color--6-3);
}

[class*=bc6-3] {
  border-color: var(--color--6-3);
}

[class*=oc6-3] {
  outline-color: var(--color--6-3);
}

[class*=fc6-3] {
  color: var(--color--6-3);
}

[class*=sc6-3] {
  -webkit-text-stroke-color: var(--color--6-3);
}

[class*=bg6-4] {
  background: var(--color--6-4);
}

[class*=bc6-4] {
  border-color: var(--color--6-4);
}

[class*=oc6-4] {
  outline-color: var(--color--6-4);
}

[class*=fc6-4] {
  color: var(--color--6-4);
}

[class*=sc6-4] {
  -webkit-text-stroke-color: var(--color--6-4);
}

[class*=bg6-5] {
  background: var(--color--6-5);
}

[class*=bc6-5] {
  border-color: var(--color--6-5);
}

[class*=oc6-5] {
  outline-color: var(--color--6-5);
}

[class*=fc6-5] {
  color: var(--color--6-5);
}

[class*=sc6-5] {
  -webkit-text-stroke-color: var(--color--6-5);
}

[class*=bg6-6] {
  background: var(--color--6-6);
}

[class*=bc6-6] {
  border-color: var(--color--6-6);
}

[class*=oc6-6] {
  outline-color: var(--color--6-6);
}

[class*=fc6-6] {
  color: var(--color--6-6);
}

[class*=sc6-6] {
  -webkit-text-stroke-color: var(--color--6-6);
}

[class*=bg6-7] {
  background: var(--color--6-7);
}

[class*=bc6-7] {
  border-color: var(--color--6-7);
}

[class*=oc6-7] {
  outline-color: var(--color--6-7);
}

[class*=fc6-7] {
  color: var(--color--6-7);
}

[class*=sc6-7] {
  -webkit-text-stroke-color: var(--color--6-7);
}

[class*=bg6-8] {
  background: var(--color--6-8);
}

[class*=bc6-8] {
  border-color: var(--color--6-8);
}

[class*=oc6-8] {
  outline-color: var(--color--6-8);
}

[class*=fc6-8] {
  color: var(--color--6-8);
}

[class*=sc6-8] {
  -webkit-text-stroke-color: var(--color--6-8);
}

[class*=bg6-9] {
  background: var(--color--6-9);
}

[class*=bc6-9] {
  border-color: var(--color--6-9);
}

[class*=oc6-9] {
  outline-color: var(--color--6-9);
}

[class*=fc6-9] {
  color: var(--color--6-9);
}

[class*=sc6-9] {
  -webkit-text-stroke-color: var(--color--6-9);
}

[class*=bg7-0] {
  background: var(--color--7-0);
}

[class*=bc7-0] {
  border-color: var(--color--7-0);
}

[class*=oc7-0] {
  outline-color: var(--color--7-0);
}

[class*=fc7-0] {
  color: var(--color--7-0);
}

[class*=sc7-0] {
  -webkit-text-stroke-color: var(--color--7-0);
}

[class*=bg7-1] {
  background: var(--color--7-1);
}

[class*=bc7-1] {
  border-color: var(--color--7-1);
}

[class*=oc7-1] {
  outline-color: var(--color--7-1);
}

[class*=fc7-1] {
  color: var(--color--7-1);
}

[class*=sc7-1] {
  -webkit-text-stroke-color: var(--color--7-1);
}

[class*=bg7-2] {
  background: var(--color--7-2);
}

[class*=bc7-2] {
  border-color: var(--color--7-2);
}

[class*=oc7-2] {
  outline-color: var(--color--7-2);
}

[class*=fc7-2] {
  color: var(--color--7-2);
}

[class*=sc7-2] {
  -webkit-text-stroke-color: var(--color--7-2);
}

[class*=bg7-3] {
  background: var(--color--7-3);
}

[class*=bc7-3] {
  border-color: var(--color--7-3);
}

[class*=oc7-3] {
  outline-color: var(--color--7-3);
}

[class*=fc7-3] {
  color: var(--color--7-3);
}

[class*=sc7-3] {
  -webkit-text-stroke-color: var(--color--7-3);
}

[class*=bg7-4] {
  background: var(--color--7-4);
}

[class*=bc7-4] {
  border-color: var(--color--7-4);
}

[class*=oc7-4] {
  outline-color: var(--color--7-4);
}

[class*=fc7-4] {
  color: var(--color--7-4);
}

[class*=sc7-4] {
  -webkit-text-stroke-color: var(--color--7-4);
}

[class*=bg7-5] {
  background: var(--color--7-5);
}

[class*=bc7-5] {
  border-color: var(--color--7-5);
}

[class*=oc7-5] {
  outline-color: var(--color--7-5);
}

[class*=fc7-5] {
  color: var(--color--7-5);
}

[class*=sc7-5] {
  -webkit-text-stroke-color: var(--color--7-5);
}

[class*=bg7-6] {
  background: var(--color--7-6);
}

[class*=bc7-6] {
  border-color: var(--color--7-6);
}

[class*=oc7-6] {
  outline-color: var(--color--7-6);
}

[class*=fc7-6] {
  color: var(--color--7-6);
}

[class*=sc7-6] {
  -webkit-text-stroke-color: var(--color--7-6);
}

[class*=bg7-7] {
  background: var(--color--7-7);
}

[class*=bc7-7] {
  border-color: var(--color--7-7);
}

[class*=oc7-7] {
  outline-color: var(--color--7-7);
}

[class*=fc7-7] {
  color: var(--color--7-7);
}

[class*=sc7-7] {
  -webkit-text-stroke-color: var(--color--7-7);
}

[class*=bg7-8] {
  background: var(--color--7-8);
}

[class*=bc7-8] {
  border-color: var(--color--7-8);
}

[class*=oc7-8] {
  outline-color: var(--color--7-8);
}

[class*=fc7-8] {
  color: var(--color--7-8);
}

[class*=sc7-8] {
  -webkit-text-stroke-color: var(--color--7-8);
}

[class*=bg7-9] {
  background: var(--color--7-9);
}

[class*=bc7-9] {
  border-color: var(--color--7-9);
}

[class*=oc7-9] {
  outline-color: var(--color--7-9);
}

[class*=fc7-9] {
  color: var(--color--7-9);
}

[class*=sc7-9] {
  -webkit-text-stroke-color: var(--color--7-9);
}

[class*=bg8-0] {
  background: var(--color--8-0);
}

[class*=bc8-0] {
  border-color: var(--color--8-0);
}

[class*=oc8-0] {
  outline-color: var(--color--8-0);
}

[class*=fc8-0] {
  color: var(--color--8-0);
}

[class*=sc8-0] {
  -webkit-text-stroke-color: var(--color--8-0);
}

[class*=bg8-1] {
  background: var(--color--8-1);
}

[class*=bc8-1] {
  border-color: var(--color--8-1);
}

[class*=oc8-1] {
  outline-color: var(--color--8-1);
}

[class*=fc8-1] {
  color: var(--color--8-1);
}

[class*=sc8-1] {
  -webkit-text-stroke-color: var(--color--8-1);
}

[class*=bg8-2] {
  background: var(--color--8-2);
}

[class*=bc8-2] {
  border-color: var(--color--8-2);
}

[class*=oc8-2] {
  outline-color: var(--color--8-2);
}

[class*=fc8-2] {
  color: var(--color--8-2);
}

[class*=sc8-2] {
  -webkit-text-stroke-color: var(--color--8-2);
}

[class*=bg8-3] {
  background: var(--color--8-3);
}

[class*=bc8-3] {
  border-color: var(--color--8-3);
}

[class*=oc8-3] {
  outline-color: var(--color--8-3);
}

[class*=fc8-3] {
  color: var(--color--8-3);
}

[class*=sc8-3] {
  -webkit-text-stroke-color: var(--color--8-3);
}

[class*=bg8-4] {
  background: var(--color--8-4);
}

[class*=bc8-4] {
  border-color: var(--color--8-4);
}

[class*=oc8-4] {
  outline-color: var(--color--8-4);
}

[class*=fc8-4] {
  color: var(--color--8-4);
}

[class*=sc8-4] {
  -webkit-text-stroke-color: var(--color--8-4);
}

[class*=bg8-5] {
  background: var(--color--8-5);
}

[class*=bc8-5] {
  border-color: var(--color--8-5);
}

[class*=oc8-5] {
  outline-color: var(--color--8-5);
}

[class*=fc8-5] {
  color: var(--color--8-5);
}

[class*=sc8-5] {
  -webkit-text-stroke-color: var(--color--8-5);
}

[class*=bg8-6] {
  background: var(--color--8-6);
}

[class*=bc8-6] {
  border-color: var(--color--8-6);
}

[class*=oc8-6] {
  outline-color: var(--color--8-6);
}

[class*=fc8-6] {
  color: var(--color--8-6);
}

[class*=sc8-6] {
  -webkit-text-stroke-color: var(--color--8-6);
}

[class*=bg8-7] {
  background: var(--color--8-7);
}

[class*=bc8-7] {
  border-color: var(--color--8-7);
}

[class*=oc8-7] {
  outline-color: var(--color--8-7);
}

[class*=fc8-7] {
  color: var(--color--8-7);
}

[class*=sc8-7] {
  -webkit-text-stroke-color: var(--color--8-7);
}

[class*=bg8-8] {
  background: var(--color--8-8);
}

[class*=bc8-8] {
  border-color: var(--color--8-8);
}

[class*=oc8-8] {
  outline-color: var(--color--8-8);
}

[class*=fc8-8] {
  color: var(--color--8-8);
}

[class*=sc8-8] {
  -webkit-text-stroke-color: var(--color--8-8);
}

[class*=bg8-9] {
  background: var(--color--8-9);
}

[class*=bc8-9] {
  border-color: var(--color--8-9);
}

[class*=oc8-9] {
  outline-color: var(--color--8-9);
}

[class*=fc8-9] {
  color: var(--color--8-9);
}

[class*=sc8-9] {
  -webkit-text-stroke-color: var(--color--8-9);
}

[class*=bg9-0] {
  background: var(--color--9-0);
}

[class*=bc9-0] {
  border-color: var(--color--9-0);
}

[class*=oc9-0] {
  outline-color: var(--color--9-0);
}

[class*=fc9-0] {
  color: var(--color--9-0);
}

[class*=sc9-0] {
  -webkit-text-stroke-color: var(--color--9-0);
}

[class*=bg9-1] {
  background: var(--color--9-1);
}

[class*=bc9-1] {
  border-color: var(--color--9-1);
}

[class*=oc9-1] {
  outline-color: var(--color--9-1);
}

[class*=fc9-1] {
  color: var(--color--9-1);
}

[class*=sc9-1] {
  -webkit-text-stroke-color: var(--color--9-1);
}

[class*=bg9-2] {
  background: var(--color--9-2);
}

[class*=bc9-2] {
  border-color: var(--color--9-2);
}

[class*=oc9-2] {
  outline-color: var(--color--9-2);
}

[class*=fc9-2] {
  color: var(--color--9-2);
}

[class*=sc9-2] {
  -webkit-text-stroke-color: var(--color--9-2);
}

[class*=bg9-3] {
  background: var(--color--9-3);
}

[class*=bc9-3] {
  border-color: var(--color--9-3);
}

[class*=oc9-3] {
  outline-color: var(--color--9-3);
}

[class*=fc9-3] {
  color: var(--color--9-3);
}

[class*=sc9-3] {
  -webkit-text-stroke-color: var(--color--9-3);
}

[class*=bg9-4] {
  background: var(--color--9-4);
}

[class*=bc9-4] {
  border-color: var(--color--9-4);
}

[class*=oc9-4] {
  outline-color: var(--color--9-4);
}

[class*=fc9-4] {
  color: var(--color--9-4);
}

[class*=sc9-4] {
  -webkit-text-stroke-color: var(--color--9-4);
}

[class*=bg9-5] {
  background: var(--color--9-5);
}

[class*=bc9-5] {
  border-color: var(--color--9-5);
}

[class*=oc9-5] {
  outline-color: var(--color--9-5);
}

[class*=fc9-5] {
  color: var(--color--9-5);
}

[class*=sc9-5] {
  -webkit-text-stroke-color: var(--color--9-5);
}

[class*=bg9-6] {
  background: var(--color--9-6);
}

[class*=bc9-6] {
  border-color: var(--color--9-6);
}

[class*=oc9-6] {
  outline-color: var(--color--9-6);
}

[class*=fc9-6] {
  color: var(--color--9-6);
}

[class*=sc9-6] {
  -webkit-text-stroke-color: var(--color--9-6);
}

[class*=bg9-7] {
  background: var(--color--9-7);
}

[class*=bc9-7] {
  border-color: var(--color--9-7);
}

[class*=oc9-7] {
  outline-color: var(--color--9-7);
}

[class*=fc9-7] {
  color: var(--color--9-7);
}

[class*=sc9-7] {
  -webkit-text-stroke-color: var(--color--9-7);
}

[class*=bg9-8] {
  background: var(--color--9-8);
}

[class*=bc9-8] {
  border-color: var(--color--9-8);
}

[class*=oc9-8] {
  outline-color: var(--color--9-8);
}

[class*=fc9-8] {
  color: var(--color--9-8);
}

[class*=sc9-8] {
  -webkit-text-stroke-color: var(--color--9-8);
}

[class*=bg9-9] {
  background: var(--color--9-9);
}

[class*=bc9-9] {
  border-color: var(--color--9-9);
}

[class*=oc9-9] {
  outline-color: var(--color--9-9);
}

[class*=fc9-9] {
  color: var(--color--9-9);
}

[class*=sc9-9] {
  -webkit-text-stroke-color: var(--color--9-9);
}

[class*=bg0-00] {
  background: var(--specials--0-00);
}

[class*=bg0-01] {
  background: var(--specials--0-01);
}

[class*=bg0-02] {
  background: var(--specials--0-02);
}

[class*=bg0-03] {
  background: var(--specials--0-03);
}

[class*=bg0-04] {
  background: var(--specials--0-04);
}

[class*=bg0-05] {
  background: var(--specials--0-05);
}

[class*=bg0-06] {
  background: var(--specials--0-06);
}

[class*=bg0-07] {
  background: var(--specials--0-07);
}

[class*=bg0-08] {
  background: var(--specials--0-08);
}

[class*=bg0-09] {
  background: var(--specials--0-09);
}

[class*=bg1-00] {
  background: var(--specials--1-00);
}

[class*=bg1-01] {
  background: var(--specials--1-01);
}

[class*=bg1-02] {
  background: var(--specials--1-02);
}

[class*=bg1-03] {
  background: var(--specials--1-03);
}

[class*=bg1-04] {
  background: var(--specials--1-04);
}

[class*=bg1-05] {
  background: var(--specials--1-05);
}

[class*=bg1-06] {
  background: var(--specials--1-06);
}

[class*=bg1-07] {
  background: var(--specials--1-07);
}

[class*=bg1-08] {
  background: var(--specials--1-08);
}

[class*=bg1-09] {
  background: var(--specials--1-09);
}

[class*=bg2-00] {
  background: var(--specials--2-00);
}

[class*=bg2-01] {
  background: var(--specials--2-01);
}

[class*=bg2-02] {
  background: var(--specials--2-02);
}

[class*=bg2-03] {
  background: var(--specials--2-03);
}

[class*=bg2-04] {
  background: var(--specials--2-04);
}

[class*=bg2-05] {
  background: var(--specials--2-05);
}

[class*=bg2-06] {
  background: var(--specials--2-06);
}

[class*=bg2-07] {
  background: var(--specials--2-07);
}

[class*=bg2-08] {
  background: var(--specials--2-08);
}

[class*=bg2-09] {
  background: var(--specials--2-09);
}

[class*=bg3-00] {
  background: var(--specials--3-00);
}

[class*=bg3-01] {
  background: var(--specials--3-01);
}

[class*=bg3-02] {
  background: var(--specials--3-02);
}

[class*=bg3-03] {
  background: var(--specials--3-03);
}

[class*=bg3-04] {
  background: var(--specials--3-04);
}

[class*=bg3-05] {
  background: var(--specials--3-05);
}

[class*=bg3-06] {
  background: var(--specials--3-06);
}

[class*=bg3-07] {
  background: var(--specials--3-07);
}

[class*=bg3-08] {
  background: var(--specials--3-08);
}

[class*=bg3-09] {
  background: var(--specials--3-09);
}

[class*=bg4-00] {
  background: var(--specials--4-00);
}

[class*=bg4-01] {
  background: var(--specials--4-01);
}

[class*=bg4-02] {
  background: var(--specials--4-02);
}

[class*=bg4-03] {
  background: var(--specials--4-03);
}

[class*=bg4-04] {
  background: var(--specials--4-04);
}

[class*=bg4-05] {
  background: var(--specials--4-05);
}

[class*=bg4-06] {
  background: var(--specials--4-06);
}

[class*=bg4-07] {
  background: var(--specials--4-07);
}

[class*=bg4-08] {
  background: var(--specials--4-08);
}

[class*=bg4-09] {
  background: var(--specials--4-09);
}

[class*=bg5-00] {
  background: var(--specials--5-00);
}

[class*=bg5-01] {
  background: var(--specials--5-01);
}

[class*=bg5-02] {
  background: var(--specials--5-02);
}

[class*=bg5-03] {
  background: var(--specials--5-03);
}

[class*=bg5-04] {
  background: var(--specials--5-04);
}

[class*=bg5-05] {
  background: var(--specials--5-05);
}

[class*=bg5-06] {
  background: var(--specials--5-06);
}

[class*=bg5-07] {
  background: var(--specials--5-07);
}

[class*=bg5-08] {
  background: var(--specials--5-08);
}

[class*=bg5-09] {
  background: var(--specials--5-09);
}

[class*=bg6-00] {
  background: var(--specials--6-00);
}

[class*=bg6-01] {
  background: var(--specials--6-01);
}

[class*=bg6-02] {
  background: var(--specials--6-02);
}

[class*=bg6-03] {
  background: var(--specials--6-03);
}

[class*=bg6-04] {
  background: var(--specials--6-04);
}

[class*=bg6-05] {
  background: var(--specials--6-05);
}

[class*=bg6-06] {
  background: var(--specials--6-06);
}

[class*=bg6-07] {
  background: var(--specials--6-07);
}

[class*=bg6-08] {
  background: var(--specials--6-08);
}

[class*=bg6-09] {
  background: var(--specials--6-09);
}

[class*=bg7-00] {
  background: var(--specials--7-00);
}

[class*=bg7-01] {
  background: var(--specials--7-01);
}

[class*=bg7-02] {
  background: var(--specials--7-02);
}

[class*=bg7-03] {
  background: var(--specials--7-03);
}

[class*=bg7-04] {
  background: var(--specials--7-04);
}

[class*=bg7-05] {
  background: var(--specials--7-05);
}

[class*=bg7-06] {
  background: var(--specials--7-06);
}

[class*=bg7-07] {
  background: var(--specials--7-07);
}

[class*=bg7-08] {
  background: var(--specials--7-08);
}

[class*=bg7-09] {
  background: var(--specials--7-09);
}

[class*=bg8-00] {
  background: var(--specials--8-00);
}

[class*=bg8-01] {
  background: var(--specials--8-01);
}

[class*=bg8-02] {
  background: var(--specials--8-02);
}

[class*=bg8-03] {
  background: var(--specials--8-03);
}

[class*=bg8-04] {
  background: var(--specials--8-04);
}

[class*=bg8-05] {
  background: var(--specials--8-05);
}

[class*=bg8-06] {
  background: var(--specials--8-06);
}

[class*=bg8-07] {
  background: var(--specials--8-07);
}

[class*=bg8-08] {
  background: var(--specials--8-08);
}

[class*=bg8-09] {
  background: var(--specials--8-09);
}

[class*=bg9-00] {
  background: var(--specials--9-00);
}

[class*=bg9-01] {
  background: var(--specials--9-01);
}

[class*=bg9-02] {
  background: var(--specials--9-02);
}

[class*=bg9-03] {
  background: var(--specials--9-03);
}

[class*=bg9-04] {
  background: var(--specials--9-04);
}

[class*=bg9-05] {
  background: var(--specials--9-05);
}

[class*=bg9-06] {
  background: var(--specials--9-06);
}

[class*=bg9-07] {
  background: var(--specials--9-07);
}

[class*=bg9-08] {
  background: var(--specials--9-08);
}

[class*=bg9-09] {
  background: var(--specials--9-09);
}

.ico-12 {
  height: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-12-12 {
  height: 0.75rem;
  width: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-16 {
  height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-16-16 {
  height: 1rem;
  width: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-20 {
  height: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-20-20 {
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-24 {
  height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-24-24 {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-32 {
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-32-32 {
  height: 2rem;
  width: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-40 {
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-40-40 {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-48 {
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-48-48 {
  height: 3rem;
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-56 {
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-56-56 {
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-64 {
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-64-64 {
  height: 4rem;
  width: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-72 {
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-72-72 {
  height: 4.5rem;
  width: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-80 {
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-80-80 {
  height: 5rem;
  width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-xs {
  height: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-xs-xs {
  height: 0.5em;
  width: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-s {
  height: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-s-s {
  height: 0.75em;
  width: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-m {
  height: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-m-m {
  height: 1em;
  width: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-l {
  height: 1.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-l-l {
  height: 1.25em;
  width: 1.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-xl {
  height: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ico-xl-xl {
  height: 1.5em;
  width: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

[class*=xs_fd0] {
  flex-direction: column;
  display: flex;
}

[class*=xs_fd1] {
  flex-direction: row;
  display: flex;
}

[class*=xs_fd2] {
  flex-direction: column-reverse;
  display: flex;
}

[class*=xs_fd3] {
  flex-direction: row-reverse;
  display: flex;
}

[class*=xs_fj0] {
  justify-content: start;
  display: flex;
}

[class*=xs_fj1] {
  justify-content: center;
  display: flex;
}

[class*=xs_fj2] {
  justify-content: end;
  display: flex;
}

[class*=xs_fj3] {
  justify-content: space-between;
  display: flex;
}

[class*=xs_fj4] {
  justify-content: space-around;
  display: flex;
}

[class*=xs_fa0] {
  align-items: start;
  display: flex;
}

[class*=xs_fa1] {
  align-items: center;
  display: flex;
}

[class*=xs_fa2] {
  align-items: end;
  display: flex;
}

[class*=xs_fa3] {
  align-items: stretch;
  display: flex;
}

[class*=xs_fa4] {
  align-items: baseline;
  display: flex;
}

[class*=xs_fw0] {
  flex-wrap: nowrap;
  display: flex;
}

[class*=xs_fw1] {
  flex-wrap: wrap;
  display: flex;
}

[class*=xs_fw2] {
  flex-wrap: wrap-reverse;
  display: flex;
}

[class*=xs_fac0] {
  align-content: flex-start;
  display: flex;
}

[class*=xs_fac1] {
  align-content: center;
  display: flex;
}

[class*=xs_fac2] {
  align-content: flex-end;
  display: flex;
}

[class*=xs_fac3] {
  align-content: stretch;
  display: flex;
}

[class*=xs_fac4] {
  align-content: space-between;
  display: flex;
}

[class*=xs_fac5] {
  align-content: space-around;
  display: flex;
}

@media (min-width: 480px) {
  [class*=sm_fd0] {
    flex-direction: column;
    display: flex;
  }
  [class*=sm_fd1] {
    flex-direction: row;
    display: flex;
  }
  [class*=sm_fd2] {
    flex-direction: column-reverse;
    display: flex;
  }
  [class*=sm_fd3] {
    flex-direction: row-reverse;
    display: flex;
  }
  [class*=sm_fj0] {
    justify-content: start;
    display: flex;
  }
  [class*=sm_fj1] {
    justify-content: center;
    display: flex;
  }
  [class*=sm_fj2] {
    justify-content: end;
    display: flex;
  }
  [class*=sm_fj3] {
    justify-content: space-between;
    display: flex;
  }
  [class*=sm_fj4] {
    justify-content: space-around;
    display: flex;
  }
  [class*=sm_fa0] {
    align-items: start;
    display: flex;
  }
  [class*=sm_fa1] {
    align-items: center;
    display: flex;
  }
  [class*=sm_fa2] {
    align-items: end;
    display: flex;
  }
  [class*=sm_fa3] {
    align-items: stretch;
    display: flex;
  }
  [class*=sm_fa4] {
    align-items: baseline;
    display: flex;
  }
  [class*=sm_fw0] {
    flex-wrap: nowrap;
    display: flex;
  }
  [class*=sm_fw1] {
    flex-wrap: wrap;
    display: flex;
  }
  [class*=sm_fw2] {
    flex-wrap: wrap-reverse;
    display: flex;
  }
  [class*=sm_fac0] {
    align-content: flex-start;
    display: flex;
  }
  [class*=sm_fac1] {
    align-content: center;
    display: flex;
  }
  [class*=sm_fac2] {
    align-content: flex-end;
    display: flex;
  }
  [class*=sm_fac3] {
    align-content: stretch;
    display: flex;
  }
  [class*=sm_fac4] {
    align-content: space-between;
    display: flex;
  }
  [class*=sm_fac5] {
    align-content: space-around;
    display: flex;
  }
}
@media (min-width: 768px) {
  [class*=md_fd0] {
    flex-direction: column;
    display: flex;
  }
  [class*=md_fd1] {
    flex-direction: row;
    display: flex;
  }
  [class*=md_fd2] {
    flex-direction: column-reverse;
    display: flex;
  }
  [class*=md_fd3] {
    flex-direction: row-reverse;
    display: flex;
  }
  [class*=md_fj0] {
    justify-content: start;
    display: flex;
  }
  [class*=md_fj1] {
    justify-content: center;
    display: flex;
  }
  [class*=md_fj2] {
    justify-content: end;
    display: flex;
  }
  [class*=md_fj3] {
    justify-content: space-between;
    display: flex;
  }
  [class*=md_fj4] {
    justify-content: space-around;
    display: flex;
  }
  [class*=md_fa0] {
    align-items: start;
    display: flex;
  }
  [class*=md_fa1] {
    align-items: center;
    display: flex;
  }
  [class*=md_fa2] {
    align-items: end;
    display: flex;
  }
  [class*=md_fa3] {
    align-items: stretch;
    display: flex;
  }
  [class*=md_fa4] {
    align-items: baseline;
    display: flex;
  }
  [class*=md_fw0] {
    flex-wrap: nowrap;
    display: flex;
  }
  [class*=md_fw1] {
    flex-wrap: wrap;
    display: flex;
  }
  [class*=md_fw2] {
    flex-wrap: wrap-reverse;
    display: flex;
  }
  [class*=md_fac0] {
    align-content: flex-start;
    display: flex;
  }
  [class*=md_fac1] {
    align-content: center;
    display: flex;
  }
  [class*=md_fac2] {
    align-content: flex-end;
    display: flex;
  }
  [class*=md_fac3] {
    align-content: stretch;
    display: flex;
  }
  [class*=md_fac4] {
    align-content: space-between;
    display: flex;
  }
  [class*=md_fac5] {
    align-content: space-around;
    display: flex;
  }
}
@media (min-width: 992px) {
  [class*=lg_fd0] {
    flex-direction: column;
    display: flex;
  }
  [class*=lg_fd1] {
    flex-direction: row;
    display: flex;
  }
  [class*=lg_fd2] {
    flex-direction: column-reverse;
    display: flex;
  }
  [class*=lg_fd3] {
    flex-direction: row-reverse;
    display: flex;
  }
  [class*=lg_fj0] {
    justify-content: start;
    display: flex;
  }
  [class*=lg_fj1] {
    justify-content: center;
    display: flex;
  }
  [class*=lg_fj2] {
    justify-content: end;
    display: flex;
  }
  [class*=lg_fj3] {
    justify-content: space-between;
    display: flex;
  }
  [class*=lg_fj4] {
    justify-content: space-around;
    display: flex;
  }
  [class*=lg_fa0] {
    align-items: start;
    display: flex;
  }
  [class*=lg_fa1] {
    align-items: center;
    display: flex;
  }
  [class*=lg_fa2] {
    align-items: end;
    display: flex;
  }
  [class*=lg_fa3] {
    align-items: stretch;
    display: flex;
  }
  [class*=lg_fa4] {
    align-items: baseline;
    display: flex;
  }
  [class*=lg_fw0] {
    flex-wrap: nowrap;
    display: flex;
  }
  [class*=lg_fw1] {
    flex-wrap: wrap;
    display: flex;
  }
  [class*=lg_fw2] {
    flex-wrap: wrap-reverse;
    display: flex;
  }
  [class*=lg_fac0] {
    align-content: flex-start;
    display: flex;
  }
  [class*=lg_fac1] {
    align-content: center;
    display: flex;
  }
  [class*=lg_fac2] {
    align-content: flex-end;
    display: flex;
  }
  [class*=lg_fac3] {
    align-content: stretch;
    display: flex;
  }
  [class*=lg_fac4] {
    align-content: space-between;
    display: flex;
  }
  [class*=lg_fac5] {
    align-content: space-around;
    display: flex;
  }
}
[class*=bg-h0-0]:hover {
  background: var(--color--0-0);
}

[class*=bc-h0-0]:hover {
  border-color: var(--color--0-0);
}

[class*=oc-h0-0]:hover {
  outline-color: var(--color--0-0);
}

[class*=fc-h0-0]:hover {
  color: var(--color--0-0);
}

[class*=sc-h0-0]:hover {
  -webkit-text-stroke-color: var(--color--0-0);
}

[class*=bg-h0-1]:hover {
  background: var(--color--0-1);
}

[class*=bc-h0-1]:hover {
  border-color: var(--color--0-1);
}

[class*=oc-h0-1]:hover {
  outline-color: var(--color--0-1);
}

[class*=fc-h0-1]:hover {
  color: var(--color--0-1);
}

[class*=sc-h0-1]:hover {
  -webkit-text-stroke-color: var(--color--0-1);
}

[class*=bg-h0-2]:hover {
  background: var(--color--0-2);
}

[class*=bc-h0-2]:hover {
  border-color: var(--color--0-2);
}

[class*=oc-h0-2]:hover {
  outline-color: var(--color--0-2);
}

[class*=fc-h0-2]:hover {
  color: var(--color--0-2);
}

[class*=sc-h0-2]:hover {
  -webkit-text-stroke-color: var(--color--0-2);
}

[class*=bg-h0-3]:hover {
  background: var(--color--0-3);
}

[class*=bc-h0-3]:hover {
  border-color: var(--color--0-3);
}

[class*=oc-h0-3]:hover {
  outline-color: var(--color--0-3);
}

[class*=fc-h0-3]:hover {
  color: var(--color--0-3);
}

[class*=sc-h0-3]:hover {
  -webkit-text-stroke-color: var(--color--0-3);
}

[class*=bg-h0-4]:hover {
  background: var(--color--0-4);
}

[class*=bc-h0-4]:hover {
  border-color: var(--color--0-4);
}

[class*=oc-h0-4]:hover {
  outline-color: var(--color--0-4);
}

[class*=fc-h0-4]:hover {
  color: var(--color--0-4);
}

[class*=sc-h0-4]:hover {
  -webkit-text-stroke-color: var(--color--0-4);
}

[class*=bg-h0-5]:hover {
  background: var(--color--0-5);
}

[class*=bc-h0-5]:hover {
  border-color: var(--color--0-5);
}

[class*=oc-h0-5]:hover {
  outline-color: var(--color--0-5);
}

[class*=fc-h0-5]:hover {
  color: var(--color--0-5);
}

[class*=sc-h0-5]:hover {
  -webkit-text-stroke-color: var(--color--0-5);
}

[class*=bg-h0-6]:hover {
  background: var(--color--0-6);
}

[class*=bc-h0-6]:hover {
  border-color: var(--color--0-6);
}

[class*=oc-h0-6]:hover {
  outline-color: var(--color--0-6);
}

[class*=fc-h0-6]:hover {
  color: var(--color--0-6);
}

[class*=sc-h0-6]:hover {
  -webkit-text-stroke-color: var(--color--0-6);
}

[class*=bg-h0-7]:hover {
  background: var(--color--0-7);
}

[class*=bc-h0-7]:hover {
  border-color: var(--color--0-7);
}

[class*=oc-h0-7]:hover {
  outline-color: var(--color--0-7);
}

[class*=fc-h0-7]:hover {
  color: var(--color--0-7);
}

[class*=sc-h0-7]:hover {
  -webkit-text-stroke-color: var(--color--0-7);
}

[class*=bg-h0-8]:hover {
  background: var(--color--0-8);
}

[class*=bc-h0-8]:hover {
  border-color: var(--color--0-8);
}

[class*=oc-h0-8]:hover {
  outline-color: var(--color--0-8);
}

[class*=fc-h0-8]:hover {
  color: var(--color--0-8);
}

[class*=sc-h0-8]:hover {
  -webkit-text-stroke-color: var(--color--0-8);
}

[class*=bg-h0-9]:hover {
  background: var(--color--0-9);
}

[class*=bc-h0-9]:hover {
  border-color: var(--color--0-9);
}

[class*=oc-h0-9]:hover {
  outline-color: var(--color--0-9);
}

[class*=fc-h0-9]:hover {
  color: var(--color--0-9);
}

[class*=sc-h0-9]:hover {
  -webkit-text-stroke-color: var(--color--0-9);
}

[class*=bg-h1-0]:hover {
  background: var(--color--1-0);
}

[class*=bc-h1-0]:hover {
  border-color: var(--color--1-0);
}

[class*=oc-h1-0]:hover {
  outline-color: var(--color--1-0);
}

[class*=fc-h1-0]:hover {
  color: var(--color--1-0);
}

[class*=sc-h1-0]:hover {
  -webkit-text-stroke-color: var(--color--1-0);
}

[class*=bg-h1-1]:hover {
  background: var(--color--1-1);
}

[class*=bc-h1-1]:hover {
  border-color: var(--color--1-1);
}

[class*=oc-h1-1]:hover {
  outline-color: var(--color--1-1);
}

[class*=fc-h1-1]:hover {
  color: var(--color--1-1);
}

[class*=sc-h1-1]:hover {
  -webkit-text-stroke-color: var(--color--1-1);
}

[class*=bg-h1-2]:hover {
  background: var(--color--1-2);
}

[class*=bc-h1-2]:hover {
  border-color: var(--color--1-2);
}

[class*=oc-h1-2]:hover {
  outline-color: var(--color--1-2);
}

[class*=fc-h1-2]:hover {
  color: var(--color--1-2);
}

[class*=sc-h1-2]:hover {
  -webkit-text-stroke-color: var(--color--1-2);
}

[class*=bg-h1-3]:hover {
  background: var(--color--1-3);
}

[class*=bc-h1-3]:hover {
  border-color: var(--color--1-3);
}

[class*=oc-h1-3]:hover {
  outline-color: var(--color--1-3);
}

[class*=fc-h1-3]:hover {
  color: var(--color--1-3);
}

[class*=sc-h1-3]:hover {
  -webkit-text-stroke-color: var(--color--1-3);
}

[class*=bg-h1-4]:hover {
  background: var(--color--1-4);
}

[class*=bc-h1-4]:hover {
  border-color: var(--color--1-4);
}

[class*=oc-h1-4]:hover {
  outline-color: var(--color--1-4);
}

[class*=fc-h1-4]:hover {
  color: var(--color--1-4);
}

[class*=sc-h1-4]:hover {
  -webkit-text-stroke-color: var(--color--1-4);
}

[class*=bg-h1-5]:hover {
  background: var(--color--1-5);
}

[class*=bc-h1-5]:hover {
  border-color: var(--color--1-5);
}

[class*=oc-h1-5]:hover {
  outline-color: var(--color--1-5);
}

[class*=fc-h1-5]:hover {
  color: var(--color--1-5);
}

[class*=sc-h1-5]:hover {
  -webkit-text-stroke-color: var(--color--1-5);
}

[class*=bg-h1-6]:hover {
  background: var(--color--1-6);
}

[class*=bc-h1-6]:hover {
  border-color: var(--color--1-6);
}

[class*=oc-h1-6]:hover {
  outline-color: var(--color--1-6);
}

[class*=fc-h1-6]:hover {
  color: var(--color--1-6);
}

[class*=sc-h1-6]:hover {
  -webkit-text-stroke-color: var(--color--1-6);
}

[class*=bg-h1-7]:hover {
  background: var(--color--1-7);
}

[class*=bc-h1-7]:hover {
  border-color: var(--color--1-7);
}

[class*=oc-h1-7]:hover {
  outline-color: var(--color--1-7);
}

[class*=fc-h1-7]:hover {
  color: var(--color--1-7);
}

[class*=sc-h1-7]:hover {
  -webkit-text-stroke-color: var(--color--1-7);
}

[class*=bg-h1-8]:hover {
  background: var(--color--1-8);
}

[class*=bc-h1-8]:hover {
  border-color: var(--color--1-8);
}

[class*=oc-h1-8]:hover {
  outline-color: var(--color--1-8);
}

[class*=fc-h1-8]:hover {
  color: var(--color--1-8);
}

[class*=sc-h1-8]:hover {
  -webkit-text-stroke-color: var(--color--1-8);
}

[class*=bg-h1-9]:hover {
  background: var(--color--1-9);
}

[class*=bc-h1-9]:hover {
  border-color: var(--color--1-9);
}

[class*=oc-h1-9]:hover {
  outline-color: var(--color--1-9);
}

[class*=fc-h1-9]:hover {
  color: var(--color--1-9);
}

[class*=sc-h1-9]:hover {
  -webkit-text-stroke-color: var(--color--1-9);
}

[class*=bg-h2-0]:hover {
  background: var(--color--2-0);
}

[class*=bc-h2-0]:hover {
  border-color: var(--color--2-0);
}

[class*=oc-h2-0]:hover {
  outline-color: var(--color--2-0);
}

[class*=fc-h2-0]:hover {
  color: var(--color--2-0);
}

[class*=sc-h2-0]:hover {
  -webkit-text-stroke-color: var(--color--2-0);
}

[class*=bg-h2-1]:hover {
  background: var(--color--2-1);
}

[class*=bc-h2-1]:hover {
  border-color: var(--color--2-1);
}

[class*=oc-h2-1]:hover {
  outline-color: var(--color--2-1);
}

[class*=fc-h2-1]:hover {
  color: var(--color--2-1);
}

[class*=sc-h2-1]:hover {
  -webkit-text-stroke-color: var(--color--2-1);
}

[class*=bg-h2-2]:hover {
  background: var(--color--2-2);
}

[class*=bc-h2-2]:hover {
  border-color: var(--color--2-2);
}

[class*=oc-h2-2]:hover {
  outline-color: var(--color--2-2);
}

[class*=fc-h2-2]:hover {
  color: var(--color--2-2);
}

[class*=sc-h2-2]:hover {
  -webkit-text-stroke-color: var(--color--2-2);
}

[class*=bg-h2-3]:hover {
  background: var(--color--2-3);
}

[class*=bc-h2-3]:hover {
  border-color: var(--color--2-3);
}

[class*=oc-h2-3]:hover {
  outline-color: var(--color--2-3);
}

[class*=fc-h2-3]:hover {
  color: var(--color--2-3);
}

[class*=sc-h2-3]:hover {
  -webkit-text-stroke-color: var(--color--2-3);
}

[class*=bg-h2-4]:hover {
  background: var(--color--2-4);
}

[class*=bc-h2-4]:hover {
  border-color: var(--color--2-4);
}

[class*=oc-h2-4]:hover {
  outline-color: var(--color--2-4);
}

[class*=fc-h2-4]:hover {
  color: var(--color--2-4);
}

[class*=sc-h2-4]:hover {
  -webkit-text-stroke-color: var(--color--2-4);
}

[class*=bg-h2-5]:hover {
  background: var(--color--2-5);
}

[class*=bc-h2-5]:hover {
  border-color: var(--color--2-5);
}

[class*=oc-h2-5]:hover {
  outline-color: var(--color--2-5);
}

[class*=fc-h2-5]:hover {
  color: var(--color--2-5);
}

[class*=sc-h2-5]:hover {
  -webkit-text-stroke-color: var(--color--2-5);
}

[class*=bg-h2-6]:hover {
  background: var(--color--2-6);
}

[class*=bc-h2-6]:hover {
  border-color: var(--color--2-6);
}

[class*=oc-h2-6]:hover {
  outline-color: var(--color--2-6);
}

[class*=fc-h2-6]:hover {
  color: var(--color--2-6);
}

[class*=sc-h2-6]:hover {
  -webkit-text-stroke-color: var(--color--2-6);
}

[class*=bg-h2-7]:hover {
  background: var(--color--2-7);
}

[class*=bc-h2-7]:hover {
  border-color: var(--color--2-7);
}

[class*=oc-h2-7]:hover {
  outline-color: var(--color--2-7);
}

[class*=fc-h2-7]:hover {
  color: var(--color--2-7);
}

[class*=sc-h2-7]:hover {
  -webkit-text-stroke-color: var(--color--2-7);
}

[class*=bg-h2-8]:hover {
  background: var(--color--2-8);
}

[class*=bc-h2-8]:hover {
  border-color: var(--color--2-8);
}

[class*=oc-h2-8]:hover {
  outline-color: var(--color--2-8);
}

[class*=fc-h2-8]:hover {
  color: var(--color--2-8);
}

[class*=sc-h2-8]:hover {
  -webkit-text-stroke-color: var(--color--2-8);
}

[class*=bg-h2-9]:hover {
  background: var(--color--2-9);
}

[class*=bc-h2-9]:hover {
  border-color: var(--color--2-9);
}

[class*=oc-h2-9]:hover {
  outline-color: var(--color--2-9);
}

[class*=fc-h2-9]:hover {
  color: var(--color--2-9);
}

[class*=sc-h2-9]:hover {
  -webkit-text-stroke-color: var(--color--2-9);
}

[class*=bg-h3-0]:hover {
  background: var(--color--3-0);
}

[class*=bc-h3-0]:hover {
  border-color: var(--color--3-0);
}

[class*=oc-h3-0]:hover {
  outline-color: var(--color--3-0);
}

[class*=fc-h3-0]:hover {
  color: var(--color--3-0);
}

[class*=sc-h3-0]:hover {
  -webkit-text-stroke-color: var(--color--3-0);
}

[class*=bg-h3-1]:hover {
  background: var(--color--3-1);
}

[class*=bc-h3-1]:hover {
  border-color: var(--color--3-1);
}

[class*=oc-h3-1]:hover {
  outline-color: var(--color--3-1);
}

[class*=fc-h3-1]:hover {
  color: var(--color--3-1);
}

[class*=sc-h3-1]:hover {
  -webkit-text-stroke-color: var(--color--3-1);
}

[class*=bg-h3-2]:hover {
  background: var(--color--3-2);
}

[class*=bc-h3-2]:hover {
  border-color: var(--color--3-2);
}

[class*=oc-h3-2]:hover {
  outline-color: var(--color--3-2);
}

[class*=fc-h3-2]:hover {
  color: var(--color--3-2);
}

[class*=sc-h3-2]:hover {
  -webkit-text-stroke-color: var(--color--3-2);
}

[class*=bg-h3-3]:hover {
  background: var(--color--3-3);
}

[class*=bc-h3-3]:hover {
  border-color: var(--color--3-3);
}

[class*=oc-h3-3]:hover {
  outline-color: var(--color--3-3);
}

[class*=fc-h3-3]:hover {
  color: var(--color--3-3);
}

[class*=sc-h3-3]:hover {
  -webkit-text-stroke-color: var(--color--3-3);
}

[class*=bg-h3-4]:hover {
  background: var(--color--3-4);
}

[class*=bc-h3-4]:hover {
  border-color: var(--color--3-4);
}

[class*=oc-h3-4]:hover {
  outline-color: var(--color--3-4);
}

[class*=fc-h3-4]:hover {
  color: var(--color--3-4);
}

[class*=sc-h3-4]:hover {
  -webkit-text-stroke-color: var(--color--3-4);
}

[class*=bg-h3-5]:hover {
  background: var(--color--3-5);
}

[class*=bc-h3-5]:hover {
  border-color: var(--color--3-5);
}

[class*=oc-h3-5]:hover {
  outline-color: var(--color--3-5);
}

[class*=fc-h3-5]:hover {
  color: var(--color--3-5);
}

[class*=sc-h3-5]:hover {
  -webkit-text-stroke-color: var(--color--3-5);
}

[class*=bg-h3-6]:hover {
  background: var(--color--3-6);
}

[class*=bc-h3-6]:hover {
  border-color: var(--color--3-6);
}

[class*=oc-h3-6]:hover {
  outline-color: var(--color--3-6);
}

[class*=fc-h3-6]:hover {
  color: var(--color--3-6);
}

[class*=sc-h3-6]:hover {
  -webkit-text-stroke-color: var(--color--3-6);
}

[class*=bg-h3-7]:hover {
  background: var(--color--3-7);
}

[class*=bc-h3-7]:hover {
  border-color: var(--color--3-7);
}

[class*=oc-h3-7]:hover {
  outline-color: var(--color--3-7);
}

[class*=fc-h3-7]:hover {
  color: var(--color--3-7);
}

[class*=sc-h3-7]:hover {
  -webkit-text-stroke-color: var(--color--3-7);
}

[class*=bg-h3-8]:hover {
  background: var(--color--3-8);
}

[class*=bc-h3-8]:hover {
  border-color: var(--color--3-8);
}

[class*=oc-h3-8]:hover {
  outline-color: var(--color--3-8);
}

[class*=fc-h3-8]:hover {
  color: var(--color--3-8);
}

[class*=sc-h3-8]:hover {
  -webkit-text-stroke-color: var(--color--3-8);
}

[class*=bg-h3-9]:hover {
  background: var(--color--3-9);
}

[class*=bc-h3-9]:hover {
  border-color: var(--color--3-9);
}

[class*=oc-h3-9]:hover {
  outline-color: var(--color--3-9);
}

[class*=fc-h3-9]:hover {
  color: var(--color--3-9);
}

[class*=sc-h3-9]:hover {
  -webkit-text-stroke-color: var(--color--3-9);
}

[class*=bg-h4-0]:hover {
  background: var(--color--4-0);
}

[class*=bc-h4-0]:hover {
  border-color: var(--color--4-0);
}

[class*=oc-h4-0]:hover {
  outline-color: var(--color--4-0);
}

[class*=fc-h4-0]:hover {
  color: var(--color--4-0);
}

[class*=sc-h4-0]:hover {
  -webkit-text-stroke-color: var(--color--4-0);
}

[class*=bg-h4-1]:hover {
  background: var(--color--4-1);
}

[class*=bc-h4-1]:hover {
  border-color: var(--color--4-1);
}

[class*=oc-h4-1]:hover {
  outline-color: var(--color--4-1);
}

[class*=fc-h4-1]:hover {
  color: var(--color--4-1);
}

[class*=sc-h4-1]:hover {
  -webkit-text-stroke-color: var(--color--4-1);
}

[class*=bg-h4-2]:hover {
  background: var(--color--4-2);
}

[class*=bc-h4-2]:hover {
  border-color: var(--color--4-2);
}

[class*=oc-h4-2]:hover {
  outline-color: var(--color--4-2);
}

[class*=fc-h4-2]:hover {
  color: var(--color--4-2);
}

[class*=sc-h4-2]:hover {
  -webkit-text-stroke-color: var(--color--4-2);
}

[class*=bg-h4-3]:hover {
  background: var(--color--4-3);
}

[class*=bc-h4-3]:hover {
  border-color: var(--color--4-3);
}

[class*=oc-h4-3]:hover {
  outline-color: var(--color--4-3);
}

[class*=fc-h4-3]:hover {
  color: var(--color--4-3);
}

[class*=sc-h4-3]:hover {
  -webkit-text-stroke-color: var(--color--4-3);
}

[class*=bg-h4-4]:hover {
  background: var(--color--4-4);
}

[class*=bc-h4-4]:hover {
  border-color: var(--color--4-4);
}

[class*=oc-h4-4]:hover {
  outline-color: var(--color--4-4);
}

[class*=fc-h4-4]:hover {
  color: var(--color--4-4);
}

[class*=sc-h4-4]:hover {
  -webkit-text-stroke-color: var(--color--4-4);
}

[class*=bg-h4-5]:hover {
  background: var(--color--4-5);
}

[class*=bc-h4-5]:hover {
  border-color: var(--color--4-5);
}

[class*=oc-h4-5]:hover {
  outline-color: var(--color--4-5);
}

[class*=fc-h4-5]:hover {
  color: var(--color--4-5);
}

[class*=sc-h4-5]:hover {
  -webkit-text-stroke-color: var(--color--4-5);
}

[class*=bg-h4-6]:hover {
  background: var(--color--4-6);
}

[class*=bc-h4-6]:hover {
  border-color: var(--color--4-6);
}

[class*=oc-h4-6]:hover {
  outline-color: var(--color--4-6);
}

[class*=fc-h4-6]:hover {
  color: var(--color--4-6);
}

[class*=sc-h4-6]:hover {
  -webkit-text-stroke-color: var(--color--4-6);
}

[class*=bg-h4-7]:hover {
  background: var(--color--4-7);
}

[class*=bc-h4-7]:hover {
  border-color: var(--color--4-7);
}

[class*=oc-h4-7]:hover {
  outline-color: var(--color--4-7);
}

[class*=fc-h4-7]:hover {
  color: var(--color--4-7);
}

[class*=sc-h4-7]:hover {
  -webkit-text-stroke-color: var(--color--4-7);
}

[class*=bg-h4-8]:hover {
  background: var(--color--4-8);
}

[class*=bc-h4-8]:hover {
  border-color: var(--color--4-8);
}

[class*=oc-h4-8]:hover {
  outline-color: var(--color--4-8);
}

[class*=fc-h4-8]:hover {
  color: var(--color--4-8);
}

[class*=sc-h4-8]:hover {
  -webkit-text-stroke-color: var(--color--4-8);
}

[class*=bg-h4-9]:hover {
  background: var(--color--4-9);
}

[class*=bc-h4-9]:hover {
  border-color: var(--color--4-9);
}

[class*=oc-h4-9]:hover {
  outline-color: var(--color--4-9);
}

[class*=fc-h4-9]:hover {
  color: var(--color--4-9);
}

[class*=sc-h4-9]:hover {
  -webkit-text-stroke-color: var(--color--4-9);
}

[class*=bg-h5-0]:hover {
  background: var(--color--5-0);
}

[class*=bc-h5-0]:hover {
  border-color: var(--color--5-0);
}

[class*=oc-h5-0]:hover {
  outline-color: var(--color--5-0);
}

[class*=fc-h5-0]:hover {
  color: var(--color--5-0);
}

[class*=sc-h5-0]:hover {
  -webkit-text-stroke-color: var(--color--5-0);
}

[class*=bg-h5-1]:hover {
  background: var(--color--5-1);
}

[class*=bc-h5-1]:hover {
  border-color: var(--color--5-1);
}

[class*=oc-h5-1]:hover {
  outline-color: var(--color--5-1);
}

[class*=fc-h5-1]:hover {
  color: var(--color--5-1);
}

[class*=sc-h5-1]:hover {
  -webkit-text-stroke-color: var(--color--5-1);
}

[class*=bg-h5-2]:hover {
  background: var(--color--5-2);
}

[class*=bc-h5-2]:hover {
  border-color: var(--color--5-2);
}

[class*=oc-h5-2]:hover {
  outline-color: var(--color--5-2);
}

[class*=fc-h5-2]:hover {
  color: var(--color--5-2);
}

[class*=sc-h5-2]:hover {
  -webkit-text-stroke-color: var(--color--5-2);
}

[class*=bg-h5-3]:hover {
  background: var(--color--5-3);
}

[class*=bc-h5-3]:hover {
  border-color: var(--color--5-3);
}

[class*=oc-h5-3]:hover {
  outline-color: var(--color--5-3);
}

[class*=fc-h5-3]:hover {
  color: var(--color--5-3);
}

[class*=sc-h5-3]:hover {
  -webkit-text-stroke-color: var(--color--5-3);
}

[class*=bg-h5-4]:hover {
  background: var(--color--5-4);
}

[class*=bc-h5-4]:hover {
  border-color: var(--color--5-4);
}

[class*=oc-h5-4]:hover {
  outline-color: var(--color--5-4);
}

[class*=fc-h5-4]:hover {
  color: var(--color--5-4);
}

[class*=sc-h5-4]:hover {
  -webkit-text-stroke-color: var(--color--5-4);
}

[class*=bg-h5-5]:hover {
  background: var(--color--5-5);
}

[class*=bc-h5-5]:hover {
  border-color: var(--color--5-5);
}

[class*=oc-h5-5]:hover {
  outline-color: var(--color--5-5);
}

[class*=fc-h5-5]:hover {
  color: var(--color--5-5);
}

[class*=sc-h5-5]:hover {
  -webkit-text-stroke-color: var(--color--5-5);
}

[class*=bg-h5-6]:hover {
  background: var(--color--5-6);
}

[class*=bc-h5-6]:hover {
  border-color: var(--color--5-6);
}

[class*=oc-h5-6]:hover {
  outline-color: var(--color--5-6);
}

[class*=fc-h5-6]:hover {
  color: var(--color--5-6);
}

[class*=sc-h5-6]:hover {
  -webkit-text-stroke-color: var(--color--5-6);
}

[class*=bg-h5-7]:hover {
  background: var(--color--5-7);
}

[class*=bc-h5-7]:hover {
  border-color: var(--color--5-7);
}

[class*=oc-h5-7]:hover {
  outline-color: var(--color--5-7);
}

[class*=fc-h5-7]:hover {
  color: var(--color--5-7);
}

[class*=sc-h5-7]:hover {
  -webkit-text-stroke-color: var(--color--5-7);
}

[class*=bg-h5-8]:hover {
  background: var(--color--5-8);
}

[class*=bc-h5-8]:hover {
  border-color: var(--color--5-8);
}

[class*=oc-h5-8]:hover {
  outline-color: var(--color--5-8);
}

[class*=fc-h5-8]:hover {
  color: var(--color--5-8);
}

[class*=sc-h5-8]:hover {
  -webkit-text-stroke-color: var(--color--5-8);
}

[class*=bg-h5-9]:hover {
  background: var(--color--5-9);
}

[class*=bc-h5-9]:hover {
  border-color: var(--color--5-9);
}

[class*=oc-h5-9]:hover {
  outline-color: var(--color--5-9);
}

[class*=fc-h5-9]:hover {
  color: var(--color--5-9);
}

[class*=sc-h5-9]:hover {
  -webkit-text-stroke-color: var(--color--5-9);
}

[class*=bg-h6-0]:hover {
  background: var(--color--6-0);
}

[class*=bc-h6-0]:hover {
  border-color: var(--color--6-0);
}

[class*=oc-h6-0]:hover {
  outline-color: var(--color--6-0);
}

[class*=fc-h6-0]:hover {
  color: var(--color--6-0);
}

[class*=sc-h6-0]:hover {
  -webkit-text-stroke-color: var(--color--6-0);
}

[class*=bg-h6-1]:hover {
  background: var(--color--6-1);
}

[class*=bc-h6-1]:hover {
  border-color: var(--color--6-1);
}

[class*=oc-h6-1]:hover {
  outline-color: var(--color--6-1);
}

[class*=fc-h6-1]:hover {
  color: var(--color--6-1);
}

[class*=sc-h6-1]:hover {
  -webkit-text-stroke-color: var(--color--6-1);
}

[class*=bg-h6-2]:hover {
  background: var(--color--6-2);
}

[class*=bc-h6-2]:hover {
  border-color: var(--color--6-2);
}

[class*=oc-h6-2]:hover {
  outline-color: var(--color--6-2);
}

[class*=fc-h6-2]:hover {
  color: var(--color--6-2);
}

[class*=sc-h6-2]:hover {
  -webkit-text-stroke-color: var(--color--6-2);
}

[class*=bg-h6-3]:hover {
  background: var(--color--6-3);
}

[class*=bc-h6-3]:hover {
  border-color: var(--color--6-3);
}

[class*=oc-h6-3]:hover {
  outline-color: var(--color--6-3);
}

[class*=fc-h6-3]:hover {
  color: var(--color--6-3);
}

[class*=sc-h6-3]:hover {
  -webkit-text-stroke-color: var(--color--6-3);
}

[class*=bg-h6-4]:hover {
  background: var(--color--6-4);
}

[class*=bc-h6-4]:hover {
  border-color: var(--color--6-4);
}

[class*=oc-h6-4]:hover {
  outline-color: var(--color--6-4);
}

[class*=fc-h6-4]:hover {
  color: var(--color--6-4);
}

[class*=sc-h6-4]:hover {
  -webkit-text-stroke-color: var(--color--6-4);
}

[class*=bg-h6-5]:hover {
  background: var(--color--6-5);
}

[class*=bc-h6-5]:hover {
  border-color: var(--color--6-5);
}

[class*=oc-h6-5]:hover {
  outline-color: var(--color--6-5);
}

[class*=fc-h6-5]:hover {
  color: var(--color--6-5);
}

[class*=sc-h6-5]:hover {
  -webkit-text-stroke-color: var(--color--6-5);
}

[class*=bg-h6-6]:hover {
  background: var(--color--6-6);
}

[class*=bc-h6-6]:hover {
  border-color: var(--color--6-6);
}

[class*=oc-h6-6]:hover {
  outline-color: var(--color--6-6);
}

[class*=fc-h6-6]:hover {
  color: var(--color--6-6);
}

[class*=sc-h6-6]:hover {
  -webkit-text-stroke-color: var(--color--6-6);
}

[class*=bg-h6-7]:hover {
  background: var(--color--6-7);
}

[class*=bc-h6-7]:hover {
  border-color: var(--color--6-7);
}

[class*=oc-h6-7]:hover {
  outline-color: var(--color--6-7);
}

[class*=fc-h6-7]:hover {
  color: var(--color--6-7);
}

[class*=sc-h6-7]:hover {
  -webkit-text-stroke-color: var(--color--6-7);
}

[class*=bg-h6-8]:hover {
  background: var(--color--6-8);
}

[class*=bc-h6-8]:hover {
  border-color: var(--color--6-8);
}

[class*=oc-h6-8]:hover {
  outline-color: var(--color--6-8);
}

[class*=fc-h6-8]:hover {
  color: var(--color--6-8);
}

[class*=sc-h6-8]:hover {
  -webkit-text-stroke-color: var(--color--6-8);
}

[class*=bg-h6-9]:hover {
  background: var(--color--6-9);
}

[class*=bc-h6-9]:hover {
  border-color: var(--color--6-9);
}

[class*=oc-h6-9]:hover {
  outline-color: var(--color--6-9);
}

[class*=fc-h6-9]:hover {
  color: var(--color--6-9);
}

[class*=sc-h6-9]:hover {
  -webkit-text-stroke-color: var(--color--6-9);
}

[class*=bg-h7-0]:hover {
  background: var(--color--7-0);
}

[class*=bc-h7-0]:hover {
  border-color: var(--color--7-0);
}

[class*=oc-h7-0]:hover {
  outline-color: var(--color--7-0);
}

[class*=fc-h7-0]:hover {
  color: var(--color--7-0);
}

[class*=sc-h7-0]:hover {
  -webkit-text-stroke-color: var(--color--7-0);
}

[class*=bg-h7-1]:hover {
  background: var(--color--7-1);
}

[class*=bc-h7-1]:hover {
  border-color: var(--color--7-1);
}

[class*=oc-h7-1]:hover {
  outline-color: var(--color--7-1);
}

[class*=fc-h7-1]:hover {
  color: var(--color--7-1);
}

[class*=sc-h7-1]:hover {
  -webkit-text-stroke-color: var(--color--7-1);
}

[class*=bg-h7-2]:hover {
  background: var(--color--7-2);
}

[class*=bc-h7-2]:hover {
  border-color: var(--color--7-2);
}

[class*=oc-h7-2]:hover {
  outline-color: var(--color--7-2);
}

[class*=fc-h7-2]:hover {
  color: var(--color--7-2);
}

[class*=sc-h7-2]:hover {
  -webkit-text-stroke-color: var(--color--7-2);
}

[class*=bg-h7-3]:hover {
  background: var(--color--7-3);
}

[class*=bc-h7-3]:hover {
  border-color: var(--color--7-3);
}

[class*=oc-h7-3]:hover {
  outline-color: var(--color--7-3);
}

[class*=fc-h7-3]:hover {
  color: var(--color--7-3);
}

[class*=sc-h7-3]:hover {
  -webkit-text-stroke-color: var(--color--7-3);
}

[class*=bg-h7-4]:hover {
  background: var(--color--7-4);
}

[class*=bc-h7-4]:hover {
  border-color: var(--color--7-4);
}

[class*=oc-h7-4]:hover {
  outline-color: var(--color--7-4);
}

[class*=fc-h7-4]:hover {
  color: var(--color--7-4);
}

[class*=sc-h7-4]:hover {
  -webkit-text-stroke-color: var(--color--7-4);
}

[class*=bg-h7-5]:hover {
  background: var(--color--7-5);
}

[class*=bc-h7-5]:hover {
  border-color: var(--color--7-5);
}

[class*=oc-h7-5]:hover {
  outline-color: var(--color--7-5);
}

[class*=fc-h7-5]:hover {
  color: var(--color--7-5);
}

[class*=sc-h7-5]:hover {
  -webkit-text-stroke-color: var(--color--7-5);
}

[class*=bg-h7-6]:hover {
  background: var(--color--7-6);
}

[class*=bc-h7-6]:hover {
  border-color: var(--color--7-6);
}

[class*=oc-h7-6]:hover {
  outline-color: var(--color--7-6);
}

[class*=fc-h7-6]:hover {
  color: var(--color--7-6);
}

[class*=sc-h7-6]:hover {
  -webkit-text-stroke-color: var(--color--7-6);
}

[class*=bg-h7-7]:hover {
  background: var(--color--7-7);
}

[class*=bc-h7-7]:hover {
  border-color: var(--color--7-7);
}

[class*=oc-h7-7]:hover {
  outline-color: var(--color--7-7);
}

[class*=fc-h7-7]:hover {
  color: var(--color--7-7);
}

[class*=sc-h7-7]:hover {
  -webkit-text-stroke-color: var(--color--7-7);
}

[class*=bg-h7-8]:hover {
  background: var(--color--7-8);
}

[class*=bc-h7-8]:hover {
  border-color: var(--color--7-8);
}

[class*=oc-h7-8]:hover {
  outline-color: var(--color--7-8);
}

[class*=fc-h7-8]:hover {
  color: var(--color--7-8);
}

[class*=sc-h7-8]:hover {
  -webkit-text-stroke-color: var(--color--7-8);
}

[class*=bg-h7-9]:hover {
  background: var(--color--7-9);
}

[class*=bc-h7-9]:hover {
  border-color: var(--color--7-9);
}

[class*=oc-h7-9]:hover {
  outline-color: var(--color--7-9);
}

[class*=fc-h7-9]:hover {
  color: var(--color--7-9);
}

[class*=sc-h7-9]:hover {
  -webkit-text-stroke-color: var(--color--7-9);
}

[class*=bg-h8-0]:hover {
  background: var(--color--8-0);
}

[class*=bc-h8-0]:hover {
  border-color: var(--color--8-0);
}

[class*=oc-h8-0]:hover {
  outline-color: var(--color--8-0);
}

[class*=fc-h8-0]:hover {
  color: var(--color--8-0);
}

[class*=sc-h8-0]:hover {
  -webkit-text-stroke-color: var(--color--8-0);
}

[class*=bg-h8-1]:hover {
  background: var(--color--8-1);
}

[class*=bc-h8-1]:hover {
  border-color: var(--color--8-1);
}

[class*=oc-h8-1]:hover {
  outline-color: var(--color--8-1);
}

[class*=fc-h8-1]:hover {
  color: var(--color--8-1);
}

[class*=sc-h8-1]:hover {
  -webkit-text-stroke-color: var(--color--8-1);
}

[class*=bg-h8-2]:hover {
  background: var(--color--8-2);
}

[class*=bc-h8-2]:hover {
  border-color: var(--color--8-2);
}

[class*=oc-h8-2]:hover {
  outline-color: var(--color--8-2);
}

[class*=fc-h8-2]:hover {
  color: var(--color--8-2);
}

[class*=sc-h8-2]:hover {
  -webkit-text-stroke-color: var(--color--8-2);
}

[class*=bg-h8-3]:hover {
  background: var(--color--8-3);
}

[class*=bc-h8-3]:hover {
  border-color: var(--color--8-3);
}

[class*=oc-h8-3]:hover {
  outline-color: var(--color--8-3);
}

[class*=fc-h8-3]:hover {
  color: var(--color--8-3);
}

[class*=sc-h8-3]:hover {
  -webkit-text-stroke-color: var(--color--8-3);
}

[class*=bg-h8-4]:hover {
  background: var(--color--8-4);
}

[class*=bc-h8-4]:hover {
  border-color: var(--color--8-4);
}

[class*=oc-h8-4]:hover {
  outline-color: var(--color--8-4);
}

[class*=fc-h8-4]:hover {
  color: var(--color--8-4);
}

[class*=sc-h8-4]:hover {
  -webkit-text-stroke-color: var(--color--8-4);
}

[class*=bg-h8-5]:hover {
  background: var(--color--8-5);
}

[class*=bc-h8-5]:hover {
  border-color: var(--color--8-5);
}

[class*=oc-h8-5]:hover {
  outline-color: var(--color--8-5);
}

[class*=fc-h8-5]:hover {
  color: var(--color--8-5);
}

[class*=sc-h8-5]:hover {
  -webkit-text-stroke-color: var(--color--8-5);
}

[class*=bg-h8-6]:hover {
  background: var(--color--8-6);
}

[class*=bc-h8-6]:hover {
  border-color: var(--color--8-6);
}

[class*=oc-h8-6]:hover {
  outline-color: var(--color--8-6);
}

[class*=fc-h8-6]:hover {
  color: var(--color--8-6);
}

[class*=sc-h8-6]:hover {
  -webkit-text-stroke-color: var(--color--8-6);
}

[class*=bg-h8-7]:hover {
  background: var(--color--8-7);
}

[class*=bc-h8-7]:hover {
  border-color: var(--color--8-7);
}

[class*=oc-h8-7]:hover {
  outline-color: var(--color--8-7);
}

[class*=fc-h8-7]:hover {
  color: var(--color--8-7);
}

[class*=sc-h8-7]:hover {
  -webkit-text-stroke-color: var(--color--8-7);
}

[class*=bg-h8-8]:hover {
  background: var(--color--8-8);
}

[class*=bc-h8-8]:hover {
  border-color: var(--color--8-8);
}

[class*=oc-h8-8]:hover {
  outline-color: var(--color--8-8);
}

[class*=fc-h8-8]:hover {
  color: var(--color--8-8);
}

[class*=sc-h8-8]:hover {
  -webkit-text-stroke-color: var(--color--8-8);
}

[class*=bg-h8-9]:hover {
  background: var(--color--8-9);
}

[class*=bc-h8-9]:hover {
  border-color: var(--color--8-9);
}

[class*=oc-h8-9]:hover {
  outline-color: var(--color--8-9);
}

[class*=fc-h8-9]:hover {
  color: var(--color--8-9);
}

[class*=sc-h8-9]:hover {
  -webkit-text-stroke-color: var(--color--8-9);
}

[class*=bg-h9-0]:hover {
  background: var(--color--9-0);
}

[class*=bc-h9-0]:hover {
  border-color: var(--color--9-0);
}

[class*=oc-h9-0]:hover {
  outline-color: var(--color--9-0);
}

[class*=fc-h9-0]:hover {
  color: var(--color--9-0);
}

[class*=sc-h9-0]:hover {
  -webkit-text-stroke-color: var(--color--9-0);
}

[class*=bg-h9-1]:hover {
  background: var(--color--9-1);
}

[class*=bc-h9-1]:hover {
  border-color: var(--color--9-1);
}

[class*=oc-h9-1]:hover {
  outline-color: var(--color--9-1);
}

[class*=fc-h9-1]:hover {
  color: var(--color--9-1);
}

[class*=sc-h9-1]:hover {
  -webkit-text-stroke-color: var(--color--9-1);
}

[class*=bg-h9-2]:hover {
  background: var(--color--9-2);
}

[class*=bc-h9-2]:hover {
  border-color: var(--color--9-2);
}

[class*=oc-h9-2]:hover {
  outline-color: var(--color--9-2);
}

[class*=fc-h9-2]:hover {
  color: var(--color--9-2);
}

[class*=sc-h9-2]:hover {
  -webkit-text-stroke-color: var(--color--9-2);
}

[class*=bg-h9-3]:hover {
  background: var(--color--9-3);
}

[class*=bc-h9-3]:hover {
  border-color: var(--color--9-3);
}

[class*=oc-h9-3]:hover {
  outline-color: var(--color--9-3);
}

[class*=fc-h9-3]:hover {
  color: var(--color--9-3);
}

[class*=sc-h9-3]:hover {
  -webkit-text-stroke-color: var(--color--9-3);
}

[class*=bg-h9-4]:hover {
  background: var(--color--9-4);
}

[class*=bc-h9-4]:hover {
  border-color: var(--color--9-4);
}

[class*=oc-h9-4]:hover {
  outline-color: var(--color--9-4);
}

[class*=fc-h9-4]:hover {
  color: var(--color--9-4);
}

[class*=sc-h9-4]:hover {
  -webkit-text-stroke-color: var(--color--9-4);
}

[class*=bg-h9-5]:hover {
  background: var(--color--9-5);
}

[class*=bc-h9-5]:hover {
  border-color: var(--color--9-5);
}

[class*=oc-h9-5]:hover {
  outline-color: var(--color--9-5);
}

[class*=fc-h9-5]:hover {
  color: var(--color--9-5);
}

[class*=sc-h9-5]:hover {
  -webkit-text-stroke-color: var(--color--9-5);
}

[class*=bg-h9-6]:hover {
  background: var(--color--9-6);
}

[class*=bc-h9-6]:hover {
  border-color: var(--color--9-6);
}

[class*=oc-h9-6]:hover {
  outline-color: var(--color--9-6);
}

[class*=fc-h9-6]:hover {
  color: var(--color--9-6);
}

[class*=sc-h9-6]:hover {
  -webkit-text-stroke-color: var(--color--9-6);
}

[class*=bg-h9-7]:hover {
  background: var(--color--9-7);
}

[class*=bc-h9-7]:hover {
  border-color: var(--color--9-7);
}

[class*=oc-h9-7]:hover {
  outline-color: var(--color--9-7);
}

[class*=fc-h9-7]:hover {
  color: var(--color--9-7);
}

[class*=sc-h9-7]:hover {
  -webkit-text-stroke-color: var(--color--9-7);
}

[class*=bg-h9-8]:hover {
  background: var(--color--9-8);
}

[class*=bc-h9-8]:hover {
  border-color: var(--color--9-8);
}

[class*=oc-h9-8]:hover {
  outline-color: var(--color--9-8);
}

[class*=fc-h9-8]:hover {
  color: var(--color--9-8);
}

[class*=sc-h9-8]:hover {
  -webkit-text-stroke-color: var(--color--9-8);
}

[class*=bg-h9-9]:hover {
  background: var(--color--9-9);
}

[class*=bc-h9-9]:hover {
  border-color: var(--color--9-9);
}

[class*=oc-h9-9]:hover {
  outline-color: var(--color--9-9);
}

[class*=fc-h9-9]:hover {
  color: var(--color--9-9);
}

[class*=sc-h9-9]:hover {
  -webkit-text-stroke-color: var(--color--9-9);
}

[class*=bg-a0-0]:active {
  background: var(--color--0-0);
}

[class*=bc-a0-0]:active {
  border-color: var(--color--0-0);
}

[class*=oc-a0-0]:active {
  outline-color: var(--color--0-0);
}

[class*=fc-a0-0]:active {
  color: var(--color--0-0);
}

[class*=sc-a0-0]:active {
  -webkit-text-stroke-color: var(--color--0-0);
}

[class*=bg-a0-1]:active {
  background: var(--color--0-1);
}

[class*=bc-a0-1]:active {
  border-color: var(--color--0-1);
}

[class*=oc-a0-1]:active {
  outline-color: var(--color--0-1);
}

[class*=fc-a0-1]:active {
  color: var(--color--0-1);
}

[class*=sc-a0-1]:active {
  -webkit-text-stroke-color: var(--color--0-1);
}

[class*=bg-a0-2]:active {
  background: var(--color--0-2);
}

[class*=bc-a0-2]:active {
  border-color: var(--color--0-2);
}

[class*=oc-a0-2]:active {
  outline-color: var(--color--0-2);
}

[class*=fc-a0-2]:active {
  color: var(--color--0-2);
}

[class*=sc-a0-2]:active {
  -webkit-text-stroke-color: var(--color--0-2);
}

[class*=bg-a0-3]:active {
  background: var(--color--0-3);
}

[class*=bc-a0-3]:active {
  border-color: var(--color--0-3);
}

[class*=oc-a0-3]:active {
  outline-color: var(--color--0-3);
}

[class*=fc-a0-3]:active {
  color: var(--color--0-3);
}

[class*=sc-a0-3]:active {
  -webkit-text-stroke-color: var(--color--0-3);
}

[class*=bg-a0-4]:active {
  background: var(--color--0-4);
}

[class*=bc-a0-4]:active {
  border-color: var(--color--0-4);
}

[class*=oc-a0-4]:active {
  outline-color: var(--color--0-4);
}

[class*=fc-a0-4]:active {
  color: var(--color--0-4);
}

[class*=sc-a0-4]:active {
  -webkit-text-stroke-color: var(--color--0-4);
}

[class*=bg-a0-5]:active {
  background: var(--color--0-5);
}

[class*=bc-a0-5]:active {
  border-color: var(--color--0-5);
}

[class*=oc-a0-5]:active {
  outline-color: var(--color--0-5);
}

[class*=fc-a0-5]:active {
  color: var(--color--0-5);
}

[class*=sc-a0-5]:active {
  -webkit-text-stroke-color: var(--color--0-5);
}

[class*=bg-a0-6]:active {
  background: var(--color--0-6);
}

[class*=bc-a0-6]:active {
  border-color: var(--color--0-6);
}

[class*=oc-a0-6]:active {
  outline-color: var(--color--0-6);
}

[class*=fc-a0-6]:active {
  color: var(--color--0-6);
}

[class*=sc-a0-6]:active {
  -webkit-text-stroke-color: var(--color--0-6);
}

[class*=bg-a0-7]:active {
  background: var(--color--0-7);
}

[class*=bc-a0-7]:active {
  border-color: var(--color--0-7);
}

[class*=oc-a0-7]:active {
  outline-color: var(--color--0-7);
}

[class*=fc-a0-7]:active {
  color: var(--color--0-7);
}

[class*=sc-a0-7]:active {
  -webkit-text-stroke-color: var(--color--0-7);
}

[class*=bg-a0-8]:active {
  background: var(--color--0-8);
}

[class*=bc-a0-8]:active {
  border-color: var(--color--0-8);
}

[class*=oc-a0-8]:active {
  outline-color: var(--color--0-8);
}

[class*=fc-a0-8]:active {
  color: var(--color--0-8);
}

[class*=sc-a0-8]:active {
  -webkit-text-stroke-color: var(--color--0-8);
}

[class*=bg-a0-9]:active {
  background: var(--color--0-9);
}

[class*=bc-a0-9]:active {
  border-color: var(--color--0-9);
}

[class*=oc-a0-9]:active {
  outline-color: var(--color--0-9);
}

[class*=fc-a0-9]:active {
  color: var(--color--0-9);
}

[class*=sc-a0-9]:active {
  -webkit-text-stroke-color: var(--color--0-9);
}

[class*=bg-a1-0]:active {
  background: var(--color--1-0);
}

[class*=bc-a1-0]:active {
  border-color: var(--color--1-0);
}

[class*=oc-a1-0]:active {
  outline-color: var(--color--1-0);
}

[class*=fc-a1-0]:active {
  color: var(--color--1-0);
}

[class*=sc-a1-0]:active {
  -webkit-text-stroke-color: var(--color--1-0);
}

[class*=bg-a1-1]:active {
  background: var(--color--1-1);
}

[class*=bc-a1-1]:active {
  border-color: var(--color--1-1);
}

[class*=oc-a1-1]:active {
  outline-color: var(--color--1-1);
}

[class*=fc-a1-1]:active {
  color: var(--color--1-1);
}

[class*=sc-a1-1]:active {
  -webkit-text-stroke-color: var(--color--1-1);
}

[class*=bg-a1-2]:active {
  background: var(--color--1-2);
}

[class*=bc-a1-2]:active {
  border-color: var(--color--1-2);
}

[class*=oc-a1-2]:active {
  outline-color: var(--color--1-2);
}

[class*=fc-a1-2]:active {
  color: var(--color--1-2);
}

[class*=sc-a1-2]:active {
  -webkit-text-stroke-color: var(--color--1-2);
}

[class*=bg-a1-3]:active {
  background: var(--color--1-3);
}

[class*=bc-a1-3]:active {
  border-color: var(--color--1-3);
}

[class*=oc-a1-3]:active {
  outline-color: var(--color--1-3);
}

[class*=fc-a1-3]:active {
  color: var(--color--1-3);
}

[class*=sc-a1-3]:active {
  -webkit-text-stroke-color: var(--color--1-3);
}

[class*=bg-a1-4]:active {
  background: var(--color--1-4);
}

[class*=bc-a1-4]:active {
  border-color: var(--color--1-4);
}

[class*=oc-a1-4]:active {
  outline-color: var(--color--1-4);
}

[class*=fc-a1-4]:active {
  color: var(--color--1-4);
}

[class*=sc-a1-4]:active {
  -webkit-text-stroke-color: var(--color--1-4);
}

[class*=bg-a1-5]:active {
  background: var(--color--1-5);
}

[class*=bc-a1-5]:active {
  border-color: var(--color--1-5);
}

[class*=oc-a1-5]:active {
  outline-color: var(--color--1-5);
}

[class*=fc-a1-5]:active {
  color: var(--color--1-5);
}

[class*=sc-a1-5]:active {
  -webkit-text-stroke-color: var(--color--1-5);
}

[class*=bg-a1-6]:active {
  background: var(--color--1-6);
}

[class*=bc-a1-6]:active {
  border-color: var(--color--1-6);
}

[class*=oc-a1-6]:active {
  outline-color: var(--color--1-6);
}

[class*=fc-a1-6]:active {
  color: var(--color--1-6);
}

[class*=sc-a1-6]:active {
  -webkit-text-stroke-color: var(--color--1-6);
}

[class*=bg-a1-7]:active {
  background: var(--color--1-7);
}

[class*=bc-a1-7]:active {
  border-color: var(--color--1-7);
}

[class*=oc-a1-7]:active {
  outline-color: var(--color--1-7);
}

[class*=fc-a1-7]:active {
  color: var(--color--1-7);
}

[class*=sc-a1-7]:active {
  -webkit-text-stroke-color: var(--color--1-7);
}

[class*=bg-a1-8]:active {
  background: var(--color--1-8);
}

[class*=bc-a1-8]:active {
  border-color: var(--color--1-8);
}

[class*=oc-a1-8]:active {
  outline-color: var(--color--1-8);
}

[class*=fc-a1-8]:active {
  color: var(--color--1-8);
}

[class*=sc-a1-8]:active {
  -webkit-text-stroke-color: var(--color--1-8);
}

[class*=bg-a1-9]:active {
  background: var(--color--1-9);
}

[class*=bc-a1-9]:active {
  border-color: var(--color--1-9);
}

[class*=oc-a1-9]:active {
  outline-color: var(--color--1-9);
}

[class*=fc-a1-9]:active {
  color: var(--color--1-9);
}

[class*=sc-a1-9]:active {
  -webkit-text-stroke-color: var(--color--1-9);
}

[class*=bg-a2-0]:active {
  background: var(--color--2-0);
}

[class*=bc-a2-0]:active {
  border-color: var(--color--2-0);
}

[class*=oc-a2-0]:active {
  outline-color: var(--color--2-0);
}

[class*=fc-a2-0]:active {
  color: var(--color--2-0);
}

[class*=sc-a2-0]:active {
  -webkit-text-stroke-color: var(--color--2-0);
}

[class*=bg-a2-1]:active {
  background: var(--color--2-1);
}

[class*=bc-a2-1]:active {
  border-color: var(--color--2-1);
}

[class*=oc-a2-1]:active {
  outline-color: var(--color--2-1);
}

[class*=fc-a2-1]:active {
  color: var(--color--2-1);
}

[class*=sc-a2-1]:active {
  -webkit-text-stroke-color: var(--color--2-1);
}

[class*=bg-a2-2]:active {
  background: var(--color--2-2);
}

[class*=bc-a2-2]:active {
  border-color: var(--color--2-2);
}

[class*=oc-a2-2]:active {
  outline-color: var(--color--2-2);
}

[class*=fc-a2-2]:active {
  color: var(--color--2-2);
}

[class*=sc-a2-2]:active {
  -webkit-text-stroke-color: var(--color--2-2);
}

[class*=bg-a2-3]:active {
  background: var(--color--2-3);
}

[class*=bc-a2-3]:active {
  border-color: var(--color--2-3);
}

[class*=oc-a2-3]:active {
  outline-color: var(--color--2-3);
}

[class*=fc-a2-3]:active {
  color: var(--color--2-3);
}

[class*=sc-a2-3]:active {
  -webkit-text-stroke-color: var(--color--2-3);
}

[class*=bg-a2-4]:active {
  background: var(--color--2-4);
}

[class*=bc-a2-4]:active {
  border-color: var(--color--2-4);
}

[class*=oc-a2-4]:active {
  outline-color: var(--color--2-4);
}

[class*=fc-a2-4]:active {
  color: var(--color--2-4);
}

[class*=sc-a2-4]:active {
  -webkit-text-stroke-color: var(--color--2-4);
}

[class*=bg-a2-5]:active {
  background: var(--color--2-5);
}

[class*=bc-a2-5]:active {
  border-color: var(--color--2-5);
}

[class*=oc-a2-5]:active {
  outline-color: var(--color--2-5);
}

[class*=fc-a2-5]:active {
  color: var(--color--2-5);
}

[class*=sc-a2-5]:active {
  -webkit-text-stroke-color: var(--color--2-5);
}

[class*=bg-a2-6]:active {
  background: var(--color--2-6);
}

[class*=bc-a2-6]:active {
  border-color: var(--color--2-6);
}

[class*=oc-a2-6]:active {
  outline-color: var(--color--2-6);
}

[class*=fc-a2-6]:active {
  color: var(--color--2-6);
}

[class*=sc-a2-6]:active {
  -webkit-text-stroke-color: var(--color--2-6);
}

[class*=bg-a2-7]:active {
  background: var(--color--2-7);
}

[class*=bc-a2-7]:active {
  border-color: var(--color--2-7);
}

[class*=oc-a2-7]:active {
  outline-color: var(--color--2-7);
}

[class*=fc-a2-7]:active {
  color: var(--color--2-7);
}

[class*=sc-a2-7]:active {
  -webkit-text-stroke-color: var(--color--2-7);
}

[class*=bg-a2-8]:active {
  background: var(--color--2-8);
}

[class*=bc-a2-8]:active {
  border-color: var(--color--2-8);
}

[class*=oc-a2-8]:active {
  outline-color: var(--color--2-8);
}

[class*=fc-a2-8]:active {
  color: var(--color--2-8);
}

[class*=sc-a2-8]:active {
  -webkit-text-stroke-color: var(--color--2-8);
}

[class*=bg-a2-9]:active {
  background: var(--color--2-9);
}

[class*=bc-a2-9]:active {
  border-color: var(--color--2-9);
}

[class*=oc-a2-9]:active {
  outline-color: var(--color--2-9);
}

[class*=fc-a2-9]:active {
  color: var(--color--2-9);
}

[class*=sc-a2-9]:active {
  -webkit-text-stroke-color: var(--color--2-9);
}

[class*=bg-a3-0]:active {
  background: var(--color--3-0);
}

[class*=bc-a3-0]:active {
  border-color: var(--color--3-0);
}

[class*=oc-a3-0]:active {
  outline-color: var(--color--3-0);
}

[class*=fc-a3-0]:active {
  color: var(--color--3-0);
}

[class*=sc-a3-0]:active {
  -webkit-text-stroke-color: var(--color--3-0);
}

[class*=bg-a3-1]:active {
  background: var(--color--3-1);
}

[class*=bc-a3-1]:active {
  border-color: var(--color--3-1);
}

[class*=oc-a3-1]:active {
  outline-color: var(--color--3-1);
}

[class*=fc-a3-1]:active {
  color: var(--color--3-1);
}

[class*=sc-a3-1]:active {
  -webkit-text-stroke-color: var(--color--3-1);
}

[class*=bg-a3-2]:active {
  background: var(--color--3-2);
}

[class*=bc-a3-2]:active {
  border-color: var(--color--3-2);
}

[class*=oc-a3-2]:active {
  outline-color: var(--color--3-2);
}

[class*=fc-a3-2]:active {
  color: var(--color--3-2);
}

[class*=sc-a3-2]:active {
  -webkit-text-stroke-color: var(--color--3-2);
}

[class*=bg-a3-3]:active {
  background: var(--color--3-3);
}

[class*=bc-a3-3]:active {
  border-color: var(--color--3-3);
}

[class*=oc-a3-3]:active {
  outline-color: var(--color--3-3);
}

[class*=fc-a3-3]:active {
  color: var(--color--3-3);
}

[class*=sc-a3-3]:active {
  -webkit-text-stroke-color: var(--color--3-3);
}

[class*=bg-a3-4]:active {
  background: var(--color--3-4);
}

[class*=bc-a3-4]:active {
  border-color: var(--color--3-4);
}

[class*=oc-a3-4]:active {
  outline-color: var(--color--3-4);
}

[class*=fc-a3-4]:active {
  color: var(--color--3-4);
}

[class*=sc-a3-4]:active {
  -webkit-text-stroke-color: var(--color--3-4);
}

[class*=bg-a3-5]:active {
  background: var(--color--3-5);
}

[class*=bc-a3-5]:active {
  border-color: var(--color--3-5);
}

[class*=oc-a3-5]:active {
  outline-color: var(--color--3-5);
}

[class*=fc-a3-5]:active {
  color: var(--color--3-5);
}

[class*=sc-a3-5]:active {
  -webkit-text-stroke-color: var(--color--3-5);
}

[class*=bg-a3-6]:active {
  background: var(--color--3-6);
}

[class*=bc-a3-6]:active {
  border-color: var(--color--3-6);
}

[class*=oc-a3-6]:active {
  outline-color: var(--color--3-6);
}

[class*=fc-a3-6]:active {
  color: var(--color--3-6);
}

[class*=sc-a3-6]:active {
  -webkit-text-stroke-color: var(--color--3-6);
}

[class*=bg-a3-7]:active {
  background: var(--color--3-7);
}

[class*=bc-a3-7]:active {
  border-color: var(--color--3-7);
}

[class*=oc-a3-7]:active {
  outline-color: var(--color--3-7);
}

[class*=fc-a3-7]:active {
  color: var(--color--3-7);
}

[class*=sc-a3-7]:active {
  -webkit-text-stroke-color: var(--color--3-7);
}

[class*=bg-a3-8]:active {
  background: var(--color--3-8);
}

[class*=bc-a3-8]:active {
  border-color: var(--color--3-8);
}

[class*=oc-a3-8]:active {
  outline-color: var(--color--3-8);
}

[class*=fc-a3-8]:active {
  color: var(--color--3-8);
}

[class*=sc-a3-8]:active {
  -webkit-text-stroke-color: var(--color--3-8);
}

[class*=bg-a3-9]:active {
  background: var(--color--3-9);
}

[class*=bc-a3-9]:active {
  border-color: var(--color--3-9);
}

[class*=oc-a3-9]:active {
  outline-color: var(--color--3-9);
}

[class*=fc-a3-9]:active {
  color: var(--color--3-9);
}

[class*=sc-a3-9]:active {
  -webkit-text-stroke-color: var(--color--3-9);
}

[class*=bg-a4-0]:active {
  background: var(--color--4-0);
}

[class*=bc-a4-0]:active {
  border-color: var(--color--4-0);
}

[class*=oc-a4-0]:active {
  outline-color: var(--color--4-0);
}

[class*=fc-a4-0]:active {
  color: var(--color--4-0);
}

[class*=sc-a4-0]:active {
  -webkit-text-stroke-color: var(--color--4-0);
}

[class*=bg-a4-1]:active {
  background: var(--color--4-1);
}

[class*=bc-a4-1]:active {
  border-color: var(--color--4-1);
}

[class*=oc-a4-1]:active {
  outline-color: var(--color--4-1);
}

[class*=fc-a4-1]:active {
  color: var(--color--4-1);
}

[class*=sc-a4-1]:active {
  -webkit-text-stroke-color: var(--color--4-1);
}

[class*=bg-a4-2]:active {
  background: var(--color--4-2);
}

[class*=bc-a4-2]:active {
  border-color: var(--color--4-2);
}

[class*=oc-a4-2]:active {
  outline-color: var(--color--4-2);
}

[class*=fc-a4-2]:active {
  color: var(--color--4-2);
}

[class*=sc-a4-2]:active {
  -webkit-text-stroke-color: var(--color--4-2);
}

[class*=bg-a4-3]:active {
  background: var(--color--4-3);
}

[class*=bc-a4-3]:active {
  border-color: var(--color--4-3);
}

[class*=oc-a4-3]:active {
  outline-color: var(--color--4-3);
}

[class*=fc-a4-3]:active {
  color: var(--color--4-3);
}

[class*=sc-a4-3]:active {
  -webkit-text-stroke-color: var(--color--4-3);
}

[class*=bg-a4-4]:active {
  background: var(--color--4-4);
}

[class*=bc-a4-4]:active {
  border-color: var(--color--4-4);
}

[class*=oc-a4-4]:active {
  outline-color: var(--color--4-4);
}

[class*=fc-a4-4]:active {
  color: var(--color--4-4);
}

[class*=sc-a4-4]:active {
  -webkit-text-stroke-color: var(--color--4-4);
}

[class*=bg-a4-5]:active {
  background: var(--color--4-5);
}

[class*=bc-a4-5]:active {
  border-color: var(--color--4-5);
}

[class*=oc-a4-5]:active {
  outline-color: var(--color--4-5);
}

[class*=fc-a4-5]:active {
  color: var(--color--4-5);
}

[class*=sc-a4-5]:active {
  -webkit-text-stroke-color: var(--color--4-5);
}

[class*=bg-a4-6]:active {
  background: var(--color--4-6);
}

[class*=bc-a4-6]:active {
  border-color: var(--color--4-6);
}

[class*=oc-a4-6]:active {
  outline-color: var(--color--4-6);
}

[class*=fc-a4-6]:active {
  color: var(--color--4-6);
}

[class*=sc-a4-6]:active {
  -webkit-text-stroke-color: var(--color--4-6);
}

[class*=bg-a4-7]:active {
  background: var(--color--4-7);
}

[class*=bc-a4-7]:active {
  border-color: var(--color--4-7);
}

[class*=oc-a4-7]:active {
  outline-color: var(--color--4-7);
}

[class*=fc-a4-7]:active {
  color: var(--color--4-7);
}

[class*=sc-a4-7]:active {
  -webkit-text-stroke-color: var(--color--4-7);
}

[class*=bg-a4-8]:active {
  background: var(--color--4-8);
}

[class*=bc-a4-8]:active {
  border-color: var(--color--4-8);
}

[class*=oc-a4-8]:active {
  outline-color: var(--color--4-8);
}

[class*=fc-a4-8]:active {
  color: var(--color--4-8);
}

[class*=sc-a4-8]:active {
  -webkit-text-stroke-color: var(--color--4-8);
}

[class*=bg-a4-9]:active {
  background: var(--color--4-9);
}

[class*=bc-a4-9]:active {
  border-color: var(--color--4-9);
}

[class*=oc-a4-9]:active {
  outline-color: var(--color--4-9);
}

[class*=fc-a4-9]:active {
  color: var(--color--4-9);
}

[class*=sc-a4-9]:active {
  -webkit-text-stroke-color: var(--color--4-9);
}

[class*=bg-a5-0]:active {
  background: var(--color--5-0);
}

[class*=bc-a5-0]:active {
  border-color: var(--color--5-0);
}

[class*=oc-a5-0]:active {
  outline-color: var(--color--5-0);
}

[class*=fc-a5-0]:active {
  color: var(--color--5-0);
}

[class*=sc-a5-0]:active {
  -webkit-text-stroke-color: var(--color--5-0);
}

[class*=bg-a5-1]:active {
  background: var(--color--5-1);
}

[class*=bc-a5-1]:active {
  border-color: var(--color--5-1);
}

[class*=oc-a5-1]:active {
  outline-color: var(--color--5-1);
}

[class*=fc-a5-1]:active {
  color: var(--color--5-1);
}

[class*=sc-a5-1]:active {
  -webkit-text-stroke-color: var(--color--5-1);
}

[class*=bg-a5-2]:active {
  background: var(--color--5-2);
}

[class*=bc-a5-2]:active {
  border-color: var(--color--5-2);
}

[class*=oc-a5-2]:active {
  outline-color: var(--color--5-2);
}

[class*=fc-a5-2]:active {
  color: var(--color--5-2);
}

[class*=sc-a5-2]:active {
  -webkit-text-stroke-color: var(--color--5-2);
}

[class*=bg-a5-3]:active {
  background: var(--color--5-3);
}

[class*=bc-a5-3]:active {
  border-color: var(--color--5-3);
}

[class*=oc-a5-3]:active {
  outline-color: var(--color--5-3);
}

[class*=fc-a5-3]:active {
  color: var(--color--5-3);
}

[class*=sc-a5-3]:active {
  -webkit-text-stroke-color: var(--color--5-3);
}

[class*=bg-a5-4]:active {
  background: var(--color--5-4);
}

[class*=bc-a5-4]:active {
  border-color: var(--color--5-4);
}

[class*=oc-a5-4]:active {
  outline-color: var(--color--5-4);
}

[class*=fc-a5-4]:active {
  color: var(--color--5-4);
}

[class*=sc-a5-4]:active {
  -webkit-text-stroke-color: var(--color--5-4);
}

[class*=bg-a5-5]:active {
  background: var(--color--5-5);
}

[class*=bc-a5-5]:active {
  border-color: var(--color--5-5);
}

[class*=oc-a5-5]:active {
  outline-color: var(--color--5-5);
}

[class*=fc-a5-5]:active {
  color: var(--color--5-5);
}

[class*=sc-a5-5]:active {
  -webkit-text-stroke-color: var(--color--5-5);
}

[class*=bg-a5-6]:active {
  background: var(--color--5-6);
}

[class*=bc-a5-6]:active {
  border-color: var(--color--5-6);
}

[class*=oc-a5-6]:active {
  outline-color: var(--color--5-6);
}

[class*=fc-a5-6]:active {
  color: var(--color--5-6);
}

[class*=sc-a5-6]:active {
  -webkit-text-stroke-color: var(--color--5-6);
}

[class*=bg-a5-7]:active {
  background: var(--color--5-7);
}

[class*=bc-a5-7]:active {
  border-color: var(--color--5-7);
}

[class*=oc-a5-7]:active {
  outline-color: var(--color--5-7);
}

[class*=fc-a5-7]:active {
  color: var(--color--5-7);
}

[class*=sc-a5-7]:active {
  -webkit-text-stroke-color: var(--color--5-7);
}

[class*=bg-a5-8]:active {
  background: var(--color--5-8);
}

[class*=bc-a5-8]:active {
  border-color: var(--color--5-8);
}

[class*=oc-a5-8]:active {
  outline-color: var(--color--5-8);
}

[class*=fc-a5-8]:active {
  color: var(--color--5-8);
}

[class*=sc-a5-8]:active {
  -webkit-text-stroke-color: var(--color--5-8);
}

[class*=bg-a5-9]:active {
  background: var(--color--5-9);
}

[class*=bc-a5-9]:active {
  border-color: var(--color--5-9);
}

[class*=oc-a5-9]:active {
  outline-color: var(--color--5-9);
}

[class*=fc-a5-9]:active {
  color: var(--color--5-9);
}

[class*=sc-a5-9]:active {
  -webkit-text-stroke-color: var(--color--5-9);
}

[class*=bg-a6-0]:active {
  background: var(--color--6-0);
}

[class*=bc-a6-0]:active {
  border-color: var(--color--6-0);
}

[class*=oc-a6-0]:active {
  outline-color: var(--color--6-0);
}

[class*=fc-a6-0]:active {
  color: var(--color--6-0);
}

[class*=sc-a6-0]:active {
  -webkit-text-stroke-color: var(--color--6-0);
}

[class*=bg-a6-1]:active {
  background: var(--color--6-1);
}

[class*=bc-a6-1]:active {
  border-color: var(--color--6-1);
}

[class*=oc-a6-1]:active {
  outline-color: var(--color--6-1);
}

[class*=fc-a6-1]:active {
  color: var(--color--6-1);
}

[class*=sc-a6-1]:active {
  -webkit-text-stroke-color: var(--color--6-1);
}

[class*=bg-a6-2]:active {
  background: var(--color--6-2);
}

[class*=bc-a6-2]:active {
  border-color: var(--color--6-2);
}

[class*=oc-a6-2]:active {
  outline-color: var(--color--6-2);
}

[class*=fc-a6-2]:active {
  color: var(--color--6-2);
}

[class*=sc-a6-2]:active {
  -webkit-text-stroke-color: var(--color--6-2);
}

[class*=bg-a6-3]:active {
  background: var(--color--6-3);
}

[class*=bc-a6-3]:active {
  border-color: var(--color--6-3);
}

[class*=oc-a6-3]:active {
  outline-color: var(--color--6-3);
}

[class*=fc-a6-3]:active {
  color: var(--color--6-3);
}

[class*=sc-a6-3]:active {
  -webkit-text-stroke-color: var(--color--6-3);
}

[class*=bg-a6-4]:active {
  background: var(--color--6-4);
}

[class*=bc-a6-4]:active {
  border-color: var(--color--6-4);
}

[class*=oc-a6-4]:active {
  outline-color: var(--color--6-4);
}

[class*=fc-a6-4]:active {
  color: var(--color--6-4);
}

[class*=sc-a6-4]:active {
  -webkit-text-stroke-color: var(--color--6-4);
}

[class*=bg-a6-5]:active {
  background: var(--color--6-5);
}

[class*=bc-a6-5]:active {
  border-color: var(--color--6-5);
}

[class*=oc-a6-5]:active {
  outline-color: var(--color--6-5);
}

[class*=fc-a6-5]:active {
  color: var(--color--6-5);
}

[class*=sc-a6-5]:active {
  -webkit-text-stroke-color: var(--color--6-5);
}

[class*=bg-a6-6]:active {
  background: var(--color--6-6);
}

[class*=bc-a6-6]:active {
  border-color: var(--color--6-6);
}

[class*=oc-a6-6]:active {
  outline-color: var(--color--6-6);
}

[class*=fc-a6-6]:active {
  color: var(--color--6-6);
}

[class*=sc-a6-6]:active {
  -webkit-text-stroke-color: var(--color--6-6);
}

[class*=bg-a6-7]:active {
  background: var(--color--6-7);
}

[class*=bc-a6-7]:active {
  border-color: var(--color--6-7);
}

[class*=oc-a6-7]:active {
  outline-color: var(--color--6-7);
}

[class*=fc-a6-7]:active {
  color: var(--color--6-7);
}

[class*=sc-a6-7]:active {
  -webkit-text-stroke-color: var(--color--6-7);
}

[class*=bg-a6-8]:active {
  background: var(--color--6-8);
}

[class*=bc-a6-8]:active {
  border-color: var(--color--6-8);
}

[class*=oc-a6-8]:active {
  outline-color: var(--color--6-8);
}

[class*=fc-a6-8]:active {
  color: var(--color--6-8);
}

[class*=sc-a6-8]:active {
  -webkit-text-stroke-color: var(--color--6-8);
}

[class*=bg-a6-9]:active {
  background: var(--color--6-9);
}

[class*=bc-a6-9]:active {
  border-color: var(--color--6-9);
}

[class*=oc-a6-9]:active {
  outline-color: var(--color--6-9);
}

[class*=fc-a6-9]:active {
  color: var(--color--6-9);
}

[class*=sc-a6-9]:active {
  -webkit-text-stroke-color: var(--color--6-9);
}

[class*=bg-a7-0]:active {
  background: var(--color--7-0);
}

[class*=bc-a7-0]:active {
  border-color: var(--color--7-0);
}

[class*=oc-a7-0]:active {
  outline-color: var(--color--7-0);
}

[class*=fc-a7-0]:active {
  color: var(--color--7-0);
}

[class*=sc-a7-0]:active {
  -webkit-text-stroke-color: var(--color--7-0);
}

[class*=bg-a7-1]:active {
  background: var(--color--7-1);
}

[class*=bc-a7-1]:active {
  border-color: var(--color--7-1);
}

[class*=oc-a7-1]:active {
  outline-color: var(--color--7-1);
}

[class*=fc-a7-1]:active {
  color: var(--color--7-1);
}

[class*=sc-a7-1]:active {
  -webkit-text-stroke-color: var(--color--7-1);
}

[class*=bg-a7-2]:active {
  background: var(--color--7-2);
}

[class*=bc-a7-2]:active {
  border-color: var(--color--7-2);
}

[class*=oc-a7-2]:active {
  outline-color: var(--color--7-2);
}

[class*=fc-a7-2]:active {
  color: var(--color--7-2);
}

[class*=sc-a7-2]:active {
  -webkit-text-stroke-color: var(--color--7-2);
}

[class*=bg-a7-3]:active {
  background: var(--color--7-3);
}

[class*=bc-a7-3]:active {
  border-color: var(--color--7-3);
}

[class*=oc-a7-3]:active {
  outline-color: var(--color--7-3);
}

[class*=fc-a7-3]:active {
  color: var(--color--7-3);
}

[class*=sc-a7-3]:active {
  -webkit-text-stroke-color: var(--color--7-3);
}

[class*=bg-a7-4]:active {
  background: var(--color--7-4);
}

[class*=bc-a7-4]:active {
  border-color: var(--color--7-4);
}

[class*=oc-a7-4]:active {
  outline-color: var(--color--7-4);
}

[class*=fc-a7-4]:active {
  color: var(--color--7-4);
}

[class*=sc-a7-4]:active {
  -webkit-text-stroke-color: var(--color--7-4);
}

[class*=bg-a7-5]:active {
  background: var(--color--7-5);
}

[class*=bc-a7-5]:active {
  border-color: var(--color--7-5);
}

[class*=oc-a7-5]:active {
  outline-color: var(--color--7-5);
}

[class*=fc-a7-5]:active {
  color: var(--color--7-5);
}

[class*=sc-a7-5]:active {
  -webkit-text-stroke-color: var(--color--7-5);
}

[class*=bg-a7-6]:active {
  background: var(--color--7-6);
}

[class*=bc-a7-6]:active {
  border-color: var(--color--7-6);
}

[class*=oc-a7-6]:active {
  outline-color: var(--color--7-6);
}

[class*=fc-a7-6]:active {
  color: var(--color--7-6);
}

[class*=sc-a7-6]:active {
  -webkit-text-stroke-color: var(--color--7-6);
}

[class*=bg-a7-7]:active {
  background: var(--color--7-7);
}

[class*=bc-a7-7]:active {
  border-color: var(--color--7-7);
}

[class*=oc-a7-7]:active {
  outline-color: var(--color--7-7);
}

[class*=fc-a7-7]:active {
  color: var(--color--7-7);
}

[class*=sc-a7-7]:active {
  -webkit-text-stroke-color: var(--color--7-7);
}

[class*=bg-a7-8]:active {
  background: var(--color--7-8);
}

[class*=bc-a7-8]:active {
  border-color: var(--color--7-8);
}

[class*=oc-a7-8]:active {
  outline-color: var(--color--7-8);
}

[class*=fc-a7-8]:active {
  color: var(--color--7-8);
}

[class*=sc-a7-8]:active {
  -webkit-text-stroke-color: var(--color--7-8);
}

[class*=bg-a7-9]:active {
  background: var(--color--7-9);
}

[class*=bc-a7-9]:active {
  border-color: var(--color--7-9);
}

[class*=oc-a7-9]:active {
  outline-color: var(--color--7-9);
}

[class*=fc-a7-9]:active {
  color: var(--color--7-9);
}

[class*=sc-a7-9]:active {
  -webkit-text-stroke-color: var(--color--7-9);
}

[class*=bg-a8-0]:active {
  background: var(--color--8-0);
}

[class*=bc-a8-0]:active {
  border-color: var(--color--8-0);
}

[class*=oc-a8-0]:active {
  outline-color: var(--color--8-0);
}

[class*=fc-a8-0]:active {
  color: var(--color--8-0);
}

[class*=sc-a8-0]:active {
  -webkit-text-stroke-color: var(--color--8-0);
}

[class*=bg-a8-1]:active {
  background: var(--color--8-1);
}

[class*=bc-a8-1]:active {
  border-color: var(--color--8-1);
}

[class*=oc-a8-1]:active {
  outline-color: var(--color--8-1);
}

[class*=fc-a8-1]:active {
  color: var(--color--8-1);
}

[class*=sc-a8-1]:active {
  -webkit-text-stroke-color: var(--color--8-1);
}

[class*=bg-a8-2]:active {
  background: var(--color--8-2);
}

[class*=bc-a8-2]:active {
  border-color: var(--color--8-2);
}

[class*=oc-a8-2]:active {
  outline-color: var(--color--8-2);
}

[class*=fc-a8-2]:active {
  color: var(--color--8-2);
}

[class*=sc-a8-2]:active {
  -webkit-text-stroke-color: var(--color--8-2);
}

[class*=bg-a8-3]:active {
  background: var(--color--8-3);
}

[class*=bc-a8-3]:active {
  border-color: var(--color--8-3);
}

[class*=oc-a8-3]:active {
  outline-color: var(--color--8-3);
}

[class*=fc-a8-3]:active {
  color: var(--color--8-3);
}

[class*=sc-a8-3]:active {
  -webkit-text-stroke-color: var(--color--8-3);
}

[class*=bg-a8-4]:active {
  background: var(--color--8-4);
}

[class*=bc-a8-4]:active {
  border-color: var(--color--8-4);
}

[class*=oc-a8-4]:active {
  outline-color: var(--color--8-4);
}

[class*=fc-a8-4]:active {
  color: var(--color--8-4);
}

[class*=sc-a8-4]:active {
  -webkit-text-stroke-color: var(--color--8-4);
}

[class*=bg-a8-5]:active {
  background: var(--color--8-5);
}

[class*=bc-a8-5]:active {
  border-color: var(--color--8-5);
}

[class*=oc-a8-5]:active {
  outline-color: var(--color--8-5);
}

[class*=fc-a8-5]:active {
  color: var(--color--8-5);
}

[class*=sc-a8-5]:active {
  -webkit-text-stroke-color: var(--color--8-5);
}

[class*=bg-a8-6]:active {
  background: var(--color--8-6);
}

[class*=bc-a8-6]:active {
  border-color: var(--color--8-6);
}

[class*=oc-a8-6]:active {
  outline-color: var(--color--8-6);
}

[class*=fc-a8-6]:active {
  color: var(--color--8-6);
}

[class*=sc-a8-6]:active {
  -webkit-text-stroke-color: var(--color--8-6);
}

[class*=bg-a8-7]:active {
  background: var(--color--8-7);
}

[class*=bc-a8-7]:active {
  border-color: var(--color--8-7);
}

[class*=oc-a8-7]:active {
  outline-color: var(--color--8-7);
}

[class*=fc-a8-7]:active {
  color: var(--color--8-7);
}

[class*=sc-a8-7]:active {
  -webkit-text-stroke-color: var(--color--8-7);
}

[class*=bg-a8-8]:active {
  background: var(--color--8-8);
}

[class*=bc-a8-8]:active {
  border-color: var(--color--8-8);
}

[class*=oc-a8-8]:active {
  outline-color: var(--color--8-8);
}

[class*=fc-a8-8]:active {
  color: var(--color--8-8);
}

[class*=sc-a8-8]:active {
  -webkit-text-stroke-color: var(--color--8-8);
}

[class*=bg-a8-9]:active {
  background: var(--color--8-9);
}

[class*=bc-a8-9]:active {
  border-color: var(--color--8-9);
}

[class*=oc-a8-9]:active {
  outline-color: var(--color--8-9);
}

[class*=fc-a8-9]:active {
  color: var(--color--8-9);
}

[class*=sc-a8-9]:active {
  -webkit-text-stroke-color: var(--color--8-9);
}

[class*=bg-a9-0]:active {
  background: var(--color--9-0);
}

[class*=bc-a9-0]:active {
  border-color: var(--color--9-0);
}

[class*=oc-a9-0]:active {
  outline-color: var(--color--9-0);
}

[class*=fc-a9-0]:active {
  color: var(--color--9-0);
}

[class*=sc-a9-0]:active {
  -webkit-text-stroke-color: var(--color--9-0);
}

[class*=bg-a9-1]:active {
  background: var(--color--9-1);
}

[class*=bc-a9-1]:active {
  border-color: var(--color--9-1);
}

[class*=oc-a9-1]:active {
  outline-color: var(--color--9-1);
}

[class*=fc-a9-1]:active {
  color: var(--color--9-1);
}

[class*=sc-a9-1]:active {
  -webkit-text-stroke-color: var(--color--9-1);
}

[class*=bg-a9-2]:active {
  background: var(--color--9-2);
}

[class*=bc-a9-2]:active {
  border-color: var(--color--9-2);
}

[class*=oc-a9-2]:active {
  outline-color: var(--color--9-2);
}

[class*=fc-a9-2]:active {
  color: var(--color--9-2);
}

[class*=sc-a9-2]:active {
  -webkit-text-stroke-color: var(--color--9-2);
}

[class*=bg-a9-3]:active {
  background: var(--color--9-3);
}

[class*=bc-a9-3]:active {
  border-color: var(--color--9-3);
}

[class*=oc-a9-3]:active {
  outline-color: var(--color--9-3);
}

[class*=fc-a9-3]:active {
  color: var(--color--9-3);
}

[class*=sc-a9-3]:active {
  -webkit-text-stroke-color: var(--color--9-3);
}

[class*=bg-a9-4]:active {
  background: var(--color--9-4);
}

[class*=bc-a9-4]:active {
  border-color: var(--color--9-4);
}

[class*=oc-a9-4]:active {
  outline-color: var(--color--9-4);
}

[class*=fc-a9-4]:active {
  color: var(--color--9-4);
}

[class*=sc-a9-4]:active {
  -webkit-text-stroke-color: var(--color--9-4);
}

[class*=bg-a9-5]:active {
  background: var(--color--9-5);
}

[class*=bc-a9-5]:active {
  border-color: var(--color--9-5);
}

[class*=oc-a9-5]:active {
  outline-color: var(--color--9-5);
}

[class*=fc-a9-5]:active {
  color: var(--color--9-5);
}

[class*=sc-a9-5]:active {
  -webkit-text-stroke-color: var(--color--9-5);
}

[class*=bg-a9-6]:active {
  background: var(--color--9-6);
}

[class*=bc-a9-6]:active {
  border-color: var(--color--9-6);
}

[class*=oc-a9-6]:active {
  outline-color: var(--color--9-6);
}

[class*=fc-a9-6]:active {
  color: var(--color--9-6);
}

[class*=sc-a9-6]:active {
  -webkit-text-stroke-color: var(--color--9-6);
}

[class*=bg-a9-7]:active {
  background: var(--color--9-7);
}

[class*=bc-a9-7]:active {
  border-color: var(--color--9-7);
}

[class*=oc-a9-7]:active {
  outline-color: var(--color--9-7);
}

[class*=fc-a9-7]:active {
  color: var(--color--9-7);
}

[class*=sc-a9-7]:active {
  -webkit-text-stroke-color: var(--color--9-7);
}

[class*=bg-a9-8]:active {
  background: var(--color--9-8);
}

[class*=bc-a9-8]:active {
  border-color: var(--color--9-8);
}

[class*=oc-a9-8]:active {
  outline-color: var(--color--9-8);
}

[class*=fc-a9-8]:active {
  color: var(--color--9-8);
}

[class*=sc-a9-8]:active {
  -webkit-text-stroke-color: var(--color--9-8);
}

[class*=bg-a9-9]:active {
  background: var(--color--9-9);
}

[class*=bc-a9-9]:active {
  border-color: var(--color--9-9);
}

[class*=oc-a9-9]:active {
  outline-color: var(--color--9-9);
}

[class*=fc-a9-9]:active {
  color: var(--color--9-9);
}

[class*=sc-a9-9]:active {
  -webkit-text-stroke-color: var(--color--9-9);
}

[class*=bg-f0-0]:focus {
  background: var(--color--0-0);
}

[class*=bc-f0-0]:focus {
  border-color: var(--color--0-0);
}

[class*=oc-f0-0]:focus {
  outline-color: var(--color--0-0);
}

[class*=fc-f0-0]:focus {
  color: var(--color--0-0);
}

[class*=sc-f0-0]:focus {
  -webkit-text-stroke-color: var(--color--0-0);
}

[class*=bg-f0-1]:focus {
  background: var(--color--0-1);
}

[class*=bc-f0-1]:focus {
  border-color: var(--color--0-1);
}

[class*=oc-f0-1]:focus {
  outline-color: var(--color--0-1);
}

[class*=fc-f0-1]:focus {
  color: var(--color--0-1);
}

[class*=sc-f0-1]:focus {
  -webkit-text-stroke-color: var(--color--0-1);
}

[class*=bg-f0-2]:focus {
  background: var(--color--0-2);
}

[class*=bc-f0-2]:focus {
  border-color: var(--color--0-2);
}

[class*=oc-f0-2]:focus {
  outline-color: var(--color--0-2);
}

[class*=fc-f0-2]:focus {
  color: var(--color--0-2);
}

[class*=sc-f0-2]:focus {
  -webkit-text-stroke-color: var(--color--0-2);
}

[class*=bg-f0-3]:focus {
  background: var(--color--0-3);
}

[class*=bc-f0-3]:focus {
  border-color: var(--color--0-3);
}

[class*=oc-f0-3]:focus {
  outline-color: var(--color--0-3);
}

[class*=fc-f0-3]:focus {
  color: var(--color--0-3);
}

[class*=sc-f0-3]:focus {
  -webkit-text-stroke-color: var(--color--0-3);
}

[class*=bg-f0-4]:focus {
  background: var(--color--0-4);
}

[class*=bc-f0-4]:focus {
  border-color: var(--color--0-4);
}

[class*=oc-f0-4]:focus {
  outline-color: var(--color--0-4);
}

[class*=fc-f0-4]:focus {
  color: var(--color--0-4);
}

[class*=sc-f0-4]:focus {
  -webkit-text-stroke-color: var(--color--0-4);
}

[class*=bg-f0-5]:focus {
  background: var(--color--0-5);
}

[class*=bc-f0-5]:focus {
  border-color: var(--color--0-5);
}

[class*=oc-f0-5]:focus {
  outline-color: var(--color--0-5);
}

[class*=fc-f0-5]:focus {
  color: var(--color--0-5);
}

[class*=sc-f0-5]:focus {
  -webkit-text-stroke-color: var(--color--0-5);
}

[class*=bg-f0-6]:focus {
  background: var(--color--0-6);
}

[class*=bc-f0-6]:focus {
  border-color: var(--color--0-6);
}

[class*=oc-f0-6]:focus {
  outline-color: var(--color--0-6);
}

[class*=fc-f0-6]:focus {
  color: var(--color--0-6);
}

[class*=sc-f0-6]:focus {
  -webkit-text-stroke-color: var(--color--0-6);
}

[class*=bg-f0-7]:focus {
  background: var(--color--0-7);
}

[class*=bc-f0-7]:focus {
  border-color: var(--color--0-7);
}

[class*=oc-f0-7]:focus {
  outline-color: var(--color--0-7);
}

[class*=fc-f0-7]:focus {
  color: var(--color--0-7);
}

[class*=sc-f0-7]:focus {
  -webkit-text-stroke-color: var(--color--0-7);
}

[class*=bg-f0-8]:focus {
  background: var(--color--0-8);
}

[class*=bc-f0-8]:focus {
  border-color: var(--color--0-8);
}

[class*=oc-f0-8]:focus {
  outline-color: var(--color--0-8);
}

[class*=fc-f0-8]:focus {
  color: var(--color--0-8);
}

[class*=sc-f0-8]:focus {
  -webkit-text-stroke-color: var(--color--0-8);
}

[class*=bg-f0-9]:focus {
  background: var(--color--0-9);
}

[class*=bc-f0-9]:focus {
  border-color: var(--color--0-9);
}

[class*=oc-f0-9]:focus {
  outline-color: var(--color--0-9);
}

[class*=fc-f0-9]:focus {
  color: var(--color--0-9);
}

[class*=sc-f0-9]:focus {
  -webkit-text-stroke-color: var(--color--0-9);
}

[class*=bg-f1-0]:focus {
  background: var(--color--1-0);
}

[class*=bc-f1-0]:focus {
  border-color: var(--color--1-0);
}

[class*=oc-f1-0]:focus {
  outline-color: var(--color--1-0);
}

[class*=fc-f1-0]:focus {
  color: var(--color--1-0);
}

[class*=sc-f1-0]:focus {
  -webkit-text-stroke-color: var(--color--1-0);
}

[class*=bg-f1-1]:focus {
  background: var(--color--1-1);
}

[class*=bc-f1-1]:focus {
  border-color: var(--color--1-1);
}

[class*=oc-f1-1]:focus {
  outline-color: var(--color--1-1);
}

[class*=fc-f1-1]:focus {
  color: var(--color--1-1);
}

[class*=sc-f1-1]:focus {
  -webkit-text-stroke-color: var(--color--1-1);
}

[class*=bg-f1-2]:focus {
  background: var(--color--1-2);
}

[class*=bc-f1-2]:focus {
  border-color: var(--color--1-2);
}

[class*=oc-f1-2]:focus {
  outline-color: var(--color--1-2);
}

[class*=fc-f1-2]:focus {
  color: var(--color--1-2);
}

[class*=sc-f1-2]:focus {
  -webkit-text-stroke-color: var(--color--1-2);
}

[class*=bg-f1-3]:focus {
  background: var(--color--1-3);
}

[class*=bc-f1-3]:focus {
  border-color: var(--color--1-3);
}

[class*=oc-f1-3]:focus {
  outline-color: var(--color--1-3);
}

[class*=fc-f1-3]:focus {
  color: var(--color--1-3);
}

[class*=sc-f1-3]:focus {
  -webkit-text-stroke-color: var(--color--1-3);
}

[class*=bg-f1-4]:focus {
  background: var(--color--1-4);
}

[class*=bc-f1-4]:focus {
  border-color: var(--color--1-4);
}

[class*=oc-f1-4]:focus {
  outline-color: var(--color--1-4);
}

[class*=fc-f1-4]:focus {
  color: var(--color--1-4);
}

[class*=sc-f1-4]:focus {
  -webkit-text-stroke-color: var(--color--1-4);
}

[class*=bg-f1-5]:focus {
  background: var(--color--1-5);
}

[class*=bc-f1-5]:focus {
  border-color: var(--color--1-5);
}

[class*=oc-f1-5]:focus {
  outline-color: var(--color--1-5);
}

[class*=fc-f1-5]:focus {
  color: var(--color--1-5);
}

[class*=sc-f1-5]:focus {
  -webkit-text-stroke-color: var(--color--1-5);
}

[class*=bg-f1-6]:focus {
  background: var(--color--1-6);
}

[class*=bc-f1-6]:focus {
  border-color: var(--color--1-6);
}

[class*=oc-f1-6]:focus {
  outline-color: var(--color--1-6);
}

[class*=fc-f1-6]:focus {
  color: var(--color--1-6);
}

[class*=sc-f1-6]:focus {
  -webkit-text-stroke-color: var(--color--1-6);
}

[class*=bg-f1-7]:focus {
  background: var(--color--1-7);
}

[class*=bc-f1-7]:focus {
  border-color: var(--color--1-7);
}

[class*=oc-f1-7]:focus {
  outline-color: var(--color--1-7);
}

[class*=fc-f1-7]:focus {
  color: var(--color--1-7);
}

[class*=sc-f1-7]:focus {
  -webkit-text-stroke-color: var(--color--1-7);
}

[class*=bg-f1-8]:focus {
  background: var(--color--1-8);
}

[class*=bc-f1-8]:focus {
  border-color: var(--color--1-8);
}

[class*=oc-f1-8]:focus {
  outline-color: var(--color--1-8);
}

[class*=fc-f1-8]:focus {
  color: var(--color--1-8);
}

[class*=sc-f1-8]:focus {
  -webkit-text-stroke-color: var(--color--1-8);
}

[class*=bg-f1-9]:focus {
  background: var(--color--1-9);
}

[class*=bc-f1-9]:focus {
  border-color: var(--color--1-9);
}

[class*=oc-f1-9]:focus {
  outline-color: var(--color--1-9);
}

[class*=fc-f1-9]:focus {
  color: var(--color--1-9);
}

[class*=sc-f1-9]:focus {
  -webkit-text-stroke-color: var(--color--1-9);
}

[class*=bg-f2-0]:focus {
  background: var(--color--2-0);
}

[class*=bc-f2-0]:focus {
  border-color: var(--color--2-0);
}

[class*=oc-f2-0]:focus {
  outline-color: var(--color--2-0);
}

[class*=fc-f2-0]:focus {
  color: var(--color--2-0);
}

[class*=sc-f2-0]:focus {
  -webkit-text-stroke-color: var(--color--2-0);
}

[class*=bg-f2-1]:focus {
  background: var(--color--2-1);
}

[class*=bc-f2-1]:focus {
  border-color: var(--color--2-1);
}

[class*=oc-f2-1]:focus {
  outline-color: var(--color--2-1);
}

[class*=fc-f2-1]:focus {
  color: var(--color--2-1);
}

[class*=sc-f2-1]:focus {
  -webkit-text-stroke-color: var(--color--2-1);
}

[class*=bg-f2-2]:focus {
  background: var(--color--2-2);
}

[class*=bc-f2-2]:focus {
  border-color: var(--color--2-2);
}

[class*=oc-f2-2]:focus {
  outline-color: var(--color--2-2);
}

[class*=fc-f2-2]:focus {
  color: var(--color--2-2);
}

[class*=sc-f2-2]:focus {
  -webkit-text-stroke-color: var(--color--2-2);
}

[class*=bg-f2-3]:focus {
  background: var(--color--2-3);
}

[class*=bc-f2-3]:focus {
  border-color: var(--color--2-3);
}

[class*=oc-f2-3]:focus {
  outline-color: var(--color--2-3);
}

[class*=fc-f2-3]:focus {
  color: var(--color--2-3);
}

[class*=sc-f2-3]:focus {
  -webkit-text-stroke-color: var(--color--2-3);
}

[class*=bg-f2-4]:focus {
  background: var(--color--2-4);
}

[class*=bc-f2-4]:focus {
  border-color: var(--color--2-4);
}

[class*=oc-f2-4]:focus {
  outline-color: var(--color--2-4);
}

[class*=fc-f2-4]:focus {
  color: var(--color--2-4);
}

[class*=sc-f2-4]:focus {
  -webkit-text-stroke-color: var(--color--2-4);
}

[class*=bg-f2-5]:focus {
  background: var(--color--2-5);
}

[class*=bc-f2-5]:focus {
  border-color: var(--color--2-5);
}

[class*=oc-f2-5]:focus {
  outline-color: var(--color--2-5);
}

[class*=fc-f2-5]:focus {
  color: var(--color--2-5);
}

[class*=sc-f2-5]:focus {
  -webkit-text-stroke-color: var(--color--2-5);
}

[class*=bg-f2-6]:focus {
  background: var(--color--2-6);
}

[class*=bc-f2-6]:focus {
  border-color: var(--color--2-6);
}

[class*=oc-f2-6]:focus {
  outline-color: var(--color--2-6);
}

[class*=fc-f2-6]:focus {
  color: var(--color--2-6);
}

[class*=sc-f2-6]:focus {
  -webkit-text-stroke-color: var(--color--2-6);
}

[class*=bg-f2-7]:focus {
  background: var(--color--2-7);
}

[class*=bc-f2-7]:focus {
  border-color: var(--color--2-7);
}

[class*=oc-f2-7]:focus {
  outline-color: var(--color--2-7);
}

[class*=fc-f2-7]:focus {
  color: var(--color--2-7);
}

[class*=sc-f2-7]:focus {
  -webkit-text-stroke-color: var(--color--2-7);
}

[class*=bg-f2-8]:focus {
  background: var(--color--2-8);
}

[class*=bc-f2-8]:focus {
  border-color: var(--color--2-8);
}

[class*=oc-f2-8]:focus {
  outline-color: var(--color--2-8);
}

[class*=fc-f2-8]:focus {
  color: var(--color--2-8);
}

[class*=sc-f2-8]:focus {
  -webkit-text-stroke-color: var(--color--2-8);
}

[class*=bg-f2-9]:focus {
  background: var(--color--2-9);
}

[class*=bc-f2-9]:focus {
  border-color: var(--color--2-9);
}

[class*=oc-f2-9]:focus {
  outline-color: var(--color--2-9);
}

[class*=fc-f2-9]:focus {
  color: var(--color--2-9);
}

[class*=sc-f2-9]:focus {
  -webkit-text-stroke-color: var(--color--2-9);
}

[class*=bg-f3-0]:focus {
  background: var(--color--3-0);
}

[class*=bc-f3-0]:focus {
  border-color: var(--color--3-0);
}

[class*=oc-f3-0]:focus {
  outline-color: var(--color--3-0);
}

[class*=fc-f3-0]:focus {
  color: var(--color--3-0);
}

[class*=sc-f3-0]:focus {
  -webkit-text-stroke-color: var(--color--3-0);
}

[class*=bg-f3-1]:focus {
  background: var(--color--3-1);
}

[class*=bc-f3-1]:focus {
  border-color: var(--color--3-1);
}

[class*=oc-f3-1]:focus {
  outline-color: var(--color--3-1);
}

[class*=fc-f3-1]:focus {
  color: var(--color--3-1);
}

[class*=sc-f3-1]:focus {
  -webkit-text-stroke-color: var(--color--3-1);
}

[class*=bg-f3-2]:focus {
  background: var(--color--3-2);
}

[class*=bc-f3-2]:focus {
  border-color: var(--color--3-2);
}

[class*=oc-f3-2]:focus {
  outline-color: var(--color--3-2);
}

[class*=fc-f3-2]:focus {
  color: var(--color--3-2);
}

[class*=sc-f3-2]:focus {
  -webkit-text-stroke-color: var(--color--3-2);
}

[class*=bg-f3-3]:focus {
  background: var(--color--3-3);
}

[class*=bc-f3-3]:focus {
  border-color: var(--color--3-3);
}

[class*=oc-f3-3]:focus {
  outline-color: var(--color--3-3);
}

[class*=fc-f3-3]:focus {
  color: var(--color--3-3);
}

[class*=sc-f3-3]:focus {
  -webkit-text-stroke-color: var(--color--3-3);
}

[class*=bg-f3-4]:focus {
  background: var(--color--3-4);
}

[class*=bc-f3-4]:focus {
  border-color: var(--color--3-4);
}

[class*=oc-f3-4]:focus {
  outline-color: var(--color--3-4);
}

[class*=fc-f3-4]:focus {
  color: var(--color--3-4);
}

[class*=sc-f3-4]:focus {
  -webkit-text-stroke-color: var(--color--3-4);
}

[class*=bg-f3-5]:focus {
  background: var(--color--3-5);
}

[class*=bc-f3-5]:focus {
  border-color: var(--color--3-5);
}

[class*=oc-f3-5]:focus {
  outline-color: var(--color--3-5);
}

[class*=fc-f3-5]:focus {
  color: var(--color--3-5);
}

[class*=sc-f3-5]:focus {
  -webkit-text-stroke-color: var(--color--3-5);
}

[class*=bg-f3-6]:focus {
  background: var(--color--3-6);
}

[class*=bc-f3-6]:focus {
  border-color: var(--color--3-6);
}

[class*=oc-f3-6]:focus {
  outline-color: var(--color--3-6);
}

[class*=fc-f3-6]:focus {
  color: var(--color--3-6);
}

[class*=sc-f3-6]:focus {
  -webkit-text-stroke-color: var(--color--3-6);
}

[class*=bg-f3-7]:focus {
  background: var(--color--3-7);
}

[class*=bc-f3-7]:focus {
  border-color: var(--color--3-7);
}

[class*=oc-f3-7]:focus {
  outline-color: var(--color--3-7);
}

[class*=fc-f3-7]:focus {
  color: var(--color--3-7);
}

[class*=sc-f3-7]:focus {
  -webkit-text-stroke-color: var(--color--3-7);
}

[class*=bg-f3-8]:focus {
  background: var(--color--3-8);
}

[class*=bc-f3-8]:focus {
  border-color: var(--color--3-8);
}

[class*=oc-f3-8]:focus {
  outline-color: var(--color--3-8);
}

[class*=fc-f3-8]:focus {
  color: var(--color--3-8);
}

[class*=sc-f3-8]:focus {
  -webkit-text-stroke-color: var(--color--3-8);
}

[class*=bg-f3-9]:focus {
  background: var(--color--3-9);
}

[class*=bc-f3-9]:focus {
  border-color: var(--color--3-9);
}

[class*=oc-f3-9]:focus {
  outline-color: var(--color--3-9);
}

[class*=fc-f3-9]:focus {
  color: var(--color--3-9);
}

[class*=sc-f3-9]:focus {
  -webkit-text-stroke-color: var(--color--3-9);
}

[class*=bg-f4-0]:focus {
  background: var(--color--4-0);
}

[class*=bc-f4-0]:focus {
  border-color: var(--color--4-0);
}

[class*=oc-f4-0]:focus {
  outline-color: var(--color--4-0);
}

[class*=fc-f4-0]:focus {
  color: var(--color--4-0);
}

[class*=sc-f4-0]:focus {
  -webkit-text-stroke-color: var(--color--4-0);
}

[class*=bg-f4-1]:focus {
  background: var(--color--4-1);
}

[class*=bc-f4-1]:focus {
  border-color: var(--color--4-1);
}

[class*=oc-f4-1]:focus {
  outline-color: var(--color--4-1);
}

[class*=fc-f4-1]:focus {
  color: var(--color--4-1);
}

[class*=sc-f4-1]:focus {
  -webkit-text-stroke-color: var(--color--4-1);
}

[class*=bg-f4-2]:focus {
  background: var(--color--4-2);
}

[class*=bc-f4-2]:focus {
  border-color: var(--color--4-2);
}

[class*=oc-f4-2]:focus {
  outline-color: var(--color--4-2);
}

[class*=fc-f4-2]:focus {
  color: var(--color--4-2);
}

[class*=sc-f4-2]:focus {
  -webkit-text-stroke-color: var(--color--4-2);
}

[class*=bg-f4-3]:focus {
  background: var(--color--4-3);
}

[class*=bc-f4-3]:focus {
  border-color: var(--color--4-3);
}

[class*=oc-f4-3]:focus {
  outline-color: var(--color--4-3);
}

[class*=fc-f4-3]:focus {
  color: var(--color--4-3);
}

[class*=sc-f4-3]:focus {
  -webkit-text-stroke-color: var(--color--4-3);
}

[class*=bg-f4-4]:focus {
  background: var(--color--4-4);
}

[class*=bc-f4-4]:focus {
  border-color: var(--color--4-4);
}

[class*=oc-f4-4]:focus {
  outline-color: var(--color--4-4);
}

[class*=fc-f4-4]:focus {
  color: var(--color--4-4);
}

[class*=sc-f4-4]:focus {
  -webkit-text-stroke-color: var(--color--4-4);
}

[class*=bg-f4-5]:focus {
  background: var(--color--4-5);
}

[class*=bc-f4-5]:focus {
  border-color: var(--color--4-5);
}

[class*=oc-f4-5]:focus {
  outline-color: var(--color--4-5);
}

[class*=fc-f4-5]:focus {
  color: var(--color--4-5);
}

[class*=sc-f4-5]:focus {
  -webkit-text-stroke-color: var(--color--4-5);
}

[class*=bg-f4-6]:focus {
  background: var(--color--4-6);
}

[class*=bc-f4-6]:focus {
  border-color: var(--color--4-6);
}

[class*=oc-f4-6]:focus {
  outline-color: var(--color--4-6);
}

[class*=fc-f4-6]:focus {
  color: var(--color--4-6);
}

[class*=sc-f4-6]:focus {
  -webkit-text-stroke-color: var(--color--4-6);
}

[class*=bg-f4-7]:focus {
  background: var(--color--4-7);
}

[class*=bc-f4-7]:focus {
  border-color: var(--color--4-7);
}

[class*=oc-f4-7]:focus {
  outline-color: var(--color--4-7);
}

[class*=fc-f4-7]:focus {
  color: var(--color--4-7);
}

[class*=sc-f4-7]:focus {
  -webkit-text-stroke-color: var(--color--4-7);
}

[class*=bg-f4-8]:focus {
  background: var(--color--4-8);
}

[class*=bc-f4-8]:focus {
  border-color: var(--color--4-8);
}

[class*=oc-f4-8]:focus {
  outline-color: var(--color--4-8);
}

[class*=fc-f4-8]:focus {
  color: var(--color--4-8);
}

[class*=sc-f4-8]:focus {
  -webkit-text-stroke-color: var(--color--4-8);
}

[class*=bg-f4-9]:focus {
  background: var(--color--4-9);
}

[class*=bc-f4-9]:focus {
  border-color: var(--color--4-9);
}

[class*=oc-f4-9]:focus {
  outline-color: var(--color--4-9);
}

[class*=fc-f4-9]:focus {
  color: var(--color--4-9);
}

[class*=sc-f4-9]:focus {
  -webkit-text-stroke-color: var(--color--4-9);
}

[class*=bg-f5-0]:focus {
  background: var(--color--5-0);
}

[class*=bc-f5-0]:focus {
  border-color: var(--color--5-0);
}

[class*=oc-f5-0]:focus {
  outline-color: var(--color--5-0);
}

[class*=fc-f5-0]:focus {
  color: var(--color--5-0);
}

[class*=sc-f5-0]:focus {
  -webkit-text-stroke-color: var(--color--5-0);
}

[class*=bg-f5-1]:focus {
  background: var(--color--5-1);
}

[class*=bc-f5-1]:focus {
  border-color: var(--color--5-1);
}

[class*=oc-f5-1]:focus {
  outline-color: var(--color--5-1);
}

[class*=fc-f5-1]:focus {
  color: var(--color--5-1);
}

[class*=sc-f5-1]:focus {
  -webkit-text-stroke-color: var(--color--5-1);
}

[class*=bg-f5-2]:focus {
  background: var(--color--5-2);
}

[class*=bc-f5-2]:focus {
  border-color: var(--color--5-2);
}

[class*=oc-f5-2]:focus {
  outline-color: var(--color--5-2);
}

[class*=fc-f5-2]:focus {
  color: var(--color--5-2);
}

[class*=sc-f5-2]:focus {
  -webkit-text-stroke-color: var(--color--5-2);
}

[class*=bg-f5-3]:focus {
  background: var(--color--5-3);
}

[class*=bc-f5-3]:focus {
  border-color: var(--color--5-3);
}

[class*=oc-f5-3]:focus {
  outline-color: var(--color--5-3);
}

[class*=fc-f5-3]:focus {
  color: var(--color--5-3);
}

[class*=sc-f5-3]:focus {
  -webkit-text-stroke-color: var(--color--5-3);
}

[class*=bg-f5-4]:focus {
  background: var(--color--5-4);
}

[class*=bc-f5-4]:focus {
  border-color: var(--color--5-4);
}

[class*=oc-f5-4]:focus {
  outline-color: var(--color--5-4);
}

[class*=fc-f5-4]:focus {
  color: var(--color--5-4);
}

[class*=sc-f5-4]:focus {
  -webkit-text-stroke-color: var(--color--5-4);
}

[class*=bg-f5-5]:focus {
  background: var(--color--5-5);
}

[class*=bc-f5-5]:focus {
  border-color: var(--color--5-5);
}

[class*=oc-f5-5]:focus {
  outline-color: var(--color--5-5);
}

[class*=fc-f5-5]:focus {
  color: var(--color--5-5);
}

[class*=sc-f5-5]:focus {
  -webkit-text-stroke-color: var(--color--5-5);
}

[class*=bg-f5-6]:focus {
  background: var(--color--5-6);
}

[class*=bc-f5-6]:focus {
  border-color: var(--color--5-6);
}

[class*=oc-f5-6]:focus {
  outline-color: var(--color--5-6);
}

[class*=fc-f5-6]:focus {
  color: var(--color--5-6);
}

[class*=sc-f5-6]:focus {
  -webkit-text-stroke-color: var(--color--5-6);
}

[class*=bg-f5-7]:focus {
  background: var(--color--5-7);
}

[class*=bc-f5-7]:focus {
  border-color: var(--color--5-7);
}

[class*=oc-f5-7]:focus {
  outline-color: var(--color--5-7);
}

[class*=fc-f5-7]:focus {
  color: var(--color--5-7);
}

[class*=sc-f5-7]:focus {
  -webkit-text-stroke-color: var(--color--5-7);
}

[class*=bg-f5-8]:focus {
  background: var(--color--5-8);
}

[class*=bc-f5-8]:focus {
  border-color: var(--color--5-8);
}

[class*=oc-f5-8]:focus {
  outline-color: var(--color--5-8);
}

[class*=fc-f5-8]:focus {
  color: var(--color--5-8);
}

[class*=sc-f5-8]:focus {
  -webkit-text-stroke-color: var(--color--5-8);
}

[class*=bg-f5-9]:focus {
  background: var(--color--5-9);
}

[class*=bc-f5-9]:focus {
  border-color: var(--color--5-9);
}

[class*=oc-f5-9]:focus {
  outline-color: var(--color--5-9);
}

[class*=fc-f5-9]:focus {
  color: var(--color--5-9);
}

[class*=sc-f5-9]:focus {
  -webkit-text-stroke-color: var(--color--5-9);
}

[class*=bg-f6-0]:focus {
  background: var(--color--6-0);
}

[class*=bc-f6-0]:focus {
  border-color: var(--color--6-0);
}

[class*=oc-f6-0]:focus {
  outline-color: var(--color--6-0);
}

[class*=fc-f6-0]:focus {
  color: var(--color--6-0);
}

[class*=sc-f6-0]:focus {
  -webkit-text-stroke-color: var(--color--6-0);
}

[class*=bg-f6-1]:focus {
  background: var(--color--6-1);
}

[class*=bc-f6-1]:focus {
  border-color: var(--color--6-1);
}

[class*=oc-f6-1]:focus {
  outline-color: var(--color--6-1);
}

[class*=fc-f6-1]:focus {
  color: var(--color--6-1);
}

[class*=sc-f6-1]:focus {
  -webkit-text-stroke-color: var(--color--6-1);
}

[class*=bg-f6-2]:focus {
  background: var(--color--6-2);
}

[class*=bc-f6-2]:focus {
  border-color: var(--color--6-2);
}

[class*=oc-f6-2]:focus {
  outline-color: var(--color--6-2);
}

[class*=fc-f6-2]:focus {
  color: var(--color--6-2);
}

[class*=sc-f6-2]:focus {
  -webkit-text-stroke-color: var(--color--6-2);
}

[class*=bg-f6-3]:focus {
  background: var(--color--6-3);
}

[class*=bc-f6-3]:focus {
  border-color: var(--color--6-3);
}

[class*=oc-f6-3]:focus {
  outline-color: var(--color--6-3);
}

[class*=fc-f6-3]:focus {
  color: var(--color--6-3);
}

[class*=sc-f6-3]:focus {
  -webkit-text-stroke-color: var(--color--6-3);
}

[class*=bg-f6-4]:focus {
  background: var(--color--6-4);
}

[class*=bc-f6-4]:focus {
  border-color: var(--color--6-4);
}

[class*=oc-f6-4]:focus {
  outline-color: var(--color--6-4);
}

[class*=fc-f6-4]:focus {
  color: var(--color--6-4);
}

[class*=sc-f6-4]:focus {
  -webkit-text-stroke-color: var(--color--6-4);
}

[class*=bg-f6-5]:focus {
  background: var(--color--6-5);
}

[class*=bc-f6-5]:focus {
  border-color: var(--color--6-5);
}

[class*=oc-f6-5]:focus {
  outline-color: var(--color--6-5);
}

[class*=fc-f6-5]:focus {
  color: var(--color--6-5);
}

[class*=sc-f6-5]:focus {
  -webkit-text-stroke-color: var(--color--6-5);
}

[class*=bg-f6-6]:focus {
  background: var(--color--6-6);
}

[class*=bc-f6-6]:focus {
  border-color: var(--color--6-6);
}

[class*=oc-f6-6]:focus {
  outline-color: var(--color--6-6);
}

[class*=fc-f6-6]:focus {
  color: var(--color--6-6);
}

[class*=sc-f6-6]:focus {
  -webkit-text-stroke-color: var(--color--6-6);
}

[class*=bg-f6-7]:focus {
  background: var(--color--6-7);
}

[class*=bc-f6-7]:focus {
  border-color: var(--color--6-7);
}

[class*=oc-f6-7]:focus {
  outline-color: var(--color--6-7);
}

[class*=fc-f6-7]:focus {
  color: var(--color--6-7);
}

[class*=sc-f6-7]:focus {
  -webkit-text-stroke-color: var(--color--6-7);
}

[class*=bg-f6-8]:focus {
  background: var(--color--6-8);
}

[class*=bc-f6-8]:focus {
  border-color: var(--color--6-8);
}

[class*=oc-f6-8]:focus {
  outline-color: var(--color--6-8);
}

[class*=fc-f6-8]:focus {
  color: var(--color--6-8);
}

[class*=sc-f6-8]:focus {
  -webkit-text-stroke-color: var(--color--6-8);
}

[class*=bg-f6-9]:focus {
  background: var(--color--6-9);
}

[class*=bc-f6-9]:focus {
  border-color: var(--color--6-9);
}

[class*=oc-f6-9]:focus {
  outline-color: var(--color--6-9);
}

[class*=fc-f6-9]:focus {
  color: var(--color--6-9);
}

[class*=sc-f6-9]:focus {
  -webkit-text-stroke-color: var(--color--6-9);
}

[class*=bg-f7-0]:focus {
  background: var(--color--7-0);
}

[class*=bc-f7-0]:focus {
  border-color: var(--color--7-0);
}

[class*=oc-f7-0]:focus {
  outline-color: var(--color--7-0);
}

[class*=fc-f7-0]:focus {
  color: var(--color--7-0);
}

[class*=sc-f7-0]:focus {
  -webkit-text-stroke-color: var(--color--7-0);
}

[class*=bg-f7-1]:focus {
  background: var(--color--7-1);
}

[class*=bc-f7-1]:focus {
  border-color: var(--color--7-1);
}

[class*=oc-f7-1]:focus {
  outline-color: var(--color--7-1);
}

[class*=fc-f7-1]:focus {
  color: var(--color--7-1);
}

[class*=sc-f7-1]:focus {
  -webkit-text-stroke-color: var(--color--7-1);
}

[class*=bg-f7-2]:focus {
  background: var(--color--7-2);
}

[class*=bc-f7-2]:focus {
  border-color: var(--color--7-2);
}

[class*=oc-f7-2]:focus {
  outline-color: var(--color--7-2);
}

[class*=fc-f7-2]:focus {
  color: var(--color--7-2);
}

[class*=sc-f7-2]:focus {
  -webkit-text-stroke-color: var(--color--7-2);
}

[class*=bg-f7-3]:focus {
  background: var(--color--7-3);
}

[class*=bc-f7-3]:focus {
  border-color: var(--color--7-3);
}

[class*=oc-f7-3]:focus {
  outline-color: var(--color--7-3);
}

[class*=fc-f7-3]:focus {
  color: var(--color--7-3);
}

[class*=sc-f7-3]:focus {
  -webkit-text-stroke-color: var(--color--7-3);
}

[class*=bg-f7-4]:focus {
  background: var(--color--7-4);
}

[class*=bc-f7-4]:focus {
  border-color: var(--color--7-4);
}

[class*=oc-f7-4]:focus {
  outline-color: var(--color--7-4);
}

[class*=fc-f7-4]:focus {
  color: var(--color--7-4);
}

[class*=sc-f7-4]:focus {
  -webkit-text-stroke-color: var(--color--7-4);
}

[class*=bg-f7-5]:focus {
  background: var(--color--7-5);
}

[class*=bc-f7-5]:focus {
  border-color: var(--color--7-5);
}

[class*=oc-f7-5]:focus {
  outline-color: var(--color--7-5);
}

[class*=fc-f7-5]:focus {
  color: var(--color--7-5);
}

[class*=sc-f7-5]:focus {
  -webkit-text-stroke-color: var(--color--7-5);
}

[class*=bg-f7-6]:focus {
  background: var(--color--7-6);
}

[class*=bc-f7-6]:focus {
  border-color: var(--color--7-6);
}

[class*=oc-f7-6]:focus {
  outline-color: var(--color--7-6);
}

[class*=fc-f7-6]:focus {
  color: var(--color--7-6);
}

[class*=sc-f7-6]:focus {
  -webkit-text-stroke-color: var(--color--7-6);
}

[class*=bg-f7-7]:focus {
  background: var(--color--7-7);
}

[class*=bc-f7-7]:focus {
  border-color: var(--color--7-7);
}

[class*=oc-f7-7]:focus {
  outline-color: var(--color--7-7);
}

[class*=fc-f7-7]:focus {
  color: var(--color--7-7);
}

[class*=sc-f7-7]:focus {
  -webkit-text-stroke-color: var(--color--7-7);
}

[class*=bg-f7-8]:focus {
  background: var(--color--7-8);
}

[class*=bc-f7-8]:focus {
  border-color: var(--color--7-8);
}

[class*=oc-f7-8]:focus {
  outline-color: var(--color--7-8);
}

[class*=fc-f7-8]:focus {
  color: var(--color--7-8);
}

[class*=sc-f7-8]:focus {
  -webkit-text-stroke-color: var(--color--7-8);
}

[class*=bg-f7-9]:focus {
  background: var(--color--7-9);
}

[class*=bc-f7-9]:focus {
  border-color: var(--color--7-9);
}

[class*=oc-f7-9]:focus {
  outline-color: var(--color--7-9);
}

[class*=fc-f7-9]:focus {
  color: var(--color--7-9);
}

[class*=sc-f7-9]:focus {
  -webkit-text-stroke-color: var(--color--7-9);
}

[class*=bg-f8-0]:focus {
  background: var(--color--8-0);
}

[class*=bc-f8-0]:focus {
  border-color: var(--color--8-0);
}

[class*=oc-f8-0]:focus {
  outline-color: var(--color--8-0);
}

[class*=fc-f8-0]:focus {
  color: var(--color--8-0);
}

[class*=sc-f8-0]:focus {
  -webkit-text-stroke-color: var(--color--8-0);
}

[class*=bg-f8-1]:focus {
  background: var(--color--8-1);
}

[class*=bc-f8-1]:focus {
  border-color: var(--color--8-1);
}

[class*=oc-f8-1]:focus {
  outline-color: var(--color--8-1);
}

[class*=fc-f8-1]:focus {
  color: var(--color--8-1);
}

[class*=sc-f8-1]:focus {
  -webkit-text-stroke-color: var(--color--8-1);
}

[class*=bg-f8-2]:focus {
  background: var(--color--8-2);
}

[class*=bc-f8-2]:focus {
  border-color: var(--color--8-2);
}

[class*=oc-f8-2]:focus {
  outline-color: var(--color--8-2);
}

[class*=fc-f8-2]:focus {
  color: var(--color--8-2);
}

[class*=sc-f8-2]:focus {
  -webkit-text-stroke-color: var(--color--8-2);
}

[class*=bg-f8-3]:focus {
  background: var(--color--8-3);
}

[class*=bc-f8-3]:focus {
  border-color: var(--color--8-3);
}

[class*=oc-f8-3]:focus {
  outline-color: var(--color--8-3);
}

[class*=fc-f8-3]:focus {
  color: var(--color--8-3);
}

[class*=sc-f8-3]:focus {
  -webkit-text-stroke-color: var(--color--8-3);
}

[class*=bg-f8-4]:focus {
  background: var(--color--8-4);
}

[class*=bc-f8-4]:focus {
  border-color: var(--color--8-4);
}

[class*=oc-f8-4]:focus {
  outline-color: var(--color--8-4);
}

[class*=fc-f8-4]:focus {
  color: var(--color--8-4);
}

[class*=sc-f8-4]:focus {
  -webkit-text-stroke-color: var(--color--8-4);
}

[class*=bg-f8-5]:focus {
  background: var(--color--8-5);
}

[class*=bc-f8-5]:focus {
  border-color: var(--color--8-5);
}

[class*=oc-f8-5]:focus {
  outline-color: var(--color--8-5);
}

[class*=fc-f8-5]:focus {
  color: var(--color--8-5);
}

[class*=sc-f8-5]:focus {
  -webkit-text-stroke-color: var(--color--8-5);
}

[class*=bg-f8-6]:focus {
  background: var(--color--8-6);
}

[class*=bc-f8-6]:focus {
  border-color: var(--color--8-6);
}

[class*=oc-f8-6]:focus {
  outline-color: var(--color--8-6);
}

[class*=fc-f8-6]:focus {
  color: var(--color--8-6);
}

[class*=sc-f8-6]:focus {
  -webkit-text-stroke-color: var(--color--8-6);
}

[class*=bg-f8-7]:focus {
  background: var(--color--8-7);
}

[class*=bc-f8-7]:focus {
  border-color: var(--color--8-7);
}

[class*=oc-f8-7]:focus {
  outline-color: var(--color--8-7);
}

[class*=fc-f8-7]:focus {
  color: var(--color--8-7);
}

[class*=sc-f8-7]:focus {
  -webkit-text-stroke-color: var(--color--8-7);
}

[class*=bg-f8-8]:focus {
  background: var(--color--8-8);
}

[class*=bc-f8-8]:focus {
  border-color: var(--color--8-8);
}

[class*=oc-f8-8]:focus {
  outline-color: var(--color--8-8);
}

[class*=fc-f8-8]:focus {
  color: var(--color--8-8);
}

[class*=sc-f8-8]:focus {
  -webkit-text-stroke-color: var(--color--8-8);
}

[class*=bg-f8-9]:focus {
  background: var(--color--8-9);
}

[class*=bc-f8-9]:focus {
  border-color: var(--color--8-9);
}

[class*=oc-f8-9]:focus {
  outline-color: var(--color--8-9);
}

[class*=fc-f8-9]:focus {
  color: var(--color--8-9);
}

[class*=sc-f8-9]:focus {
  -webkit-text-stroke-color: var(--color--8-9);
}

[class*=bg-f9-0]:focus {
  background: var(--color--9-0);
}

[class*=bc-f9-0]:focus {
  border-color: var(--color--9-0);
}

[class*=oc-f9-0]:focus {
  outline-color: var(--color--9-0);
}

[class*=fc-f9-0]:focus {
  color: var(--color--9-0);
}

[class*=sc-f9-0]:focus {
  -webkit-text-stroke-color: var(--color--9-0);
}

[class*=bg-f9-1]:focus {
  background: var(--color--9-1);
}

[class*=bc-f9-1]:focus {
  border-color: var(--color--9-1);
}

[class*=oc-f9-1]:focus {
  outline-color: var(--color--9-1);
}

[class*=fc-f9-1]:focus {
  color: var(--color--9-1);
}

[class*=sc-f9-1]:focus {
  -webkit-text-stroke-color: var(--color--9-1);
}

[class*=bg-f9-2]:focus {
  background: var(--color--9-2);
}

[class*=bc-f9-2]:focus {
  border-color: var(--color--9-2);
}

[class*=oc-f9-2]:focus {
  outline-color: var(--color--9-2);
}

[class*=fc-f9-2]:focus {
  color: var(--color--9-2);
}

[class*=sc-f9-2]:focus {
  -webkit-text-stroke-color: var(--color--9-2);
}

[class*=bg-f9-3]:focus {
  background: var(--color--9-3);
}

[class*=bc-f9-3]:focus {
  border-color: var(--color--9-3);
}

[class*=oc-f9-3]:focus {
  outline-color: var(--color--9-3);
}

[class*=fc-f9-3]:focus {
  color: var(--color--9-3);
}

[class*=sc-f9-3]:focus {
  -webkit-text-stroke-color: var(--color--9-3);
}

[class*=bg-f9-4]:focus {
  background: var(--color--9-4);
}

[class*=bc-f9-4]:focus {
  border-color: var(--color--9-4);
}

[class*=oc-f9-4]:focus {
  outline-color: var(--color--9-4);
}

[class*=fc-f9-4]:focus {
  color: var(--color--9-4);
}

[class*=sc-f9-4]:focus {
  -webkit-text-stroke-color: var(--color--9-4);
}

[class*=bg-f9-5]:focus {
  background: var(--color--9-5);
}

[class*=bc-f9-5]:focus {
  border-color: var(--color--9-5);
}

[class*=oc-f9-5]:focus {
  outline-color: var(--color--9-5);
}

[class*=fc-f9-5]:focus {
  color: var(--color--9-5);
}

[class*=sc-f9-5]:focus {
  -webkit-text-stroke-color: var(--color--9-5);
}

[class*=bg-f9-6]:focus {
  background: var(--color--9-6);
}

[class*=bc-f9-6]:focus {
  border-color: var(--color--9-6);
}

[class*=oc-f9-6]:focus {
  outline-color: var(--color--9-6);
}

[class*=fc-f9-6]:focus {
  color: var(--color--9-6);
}

[class*=sc-f9-6]:focus {
  -webkit-text-stroke-color: var(--color--9-6);
}

[class*=bg-f9-7]:focus {
  background: var(--color--9-7);
}

[class*=bc-f9-7]:focus {
  border-color: var(--color--9-7);
}

[class*=oc-f9-7]:focus {
  outline-color: var(--color--9-7);
}

[class*=fc-f9-7]:focus {
  color: var(--color--9-7);
}

[class*=sc-f9-7]:focus {
  -webkit-text-stroke-color: var(--color--9-7);
}

[class*=bg-f9-8]:focus {
  background: var(--color--9-8);
}

[class*=bc-f9-8]:focus {
  border-color: var(--color--9-8);
}

[class*=oc-f9-8]:focus {
  outline-color: var(--color--9-8);
}

[class*=fc-f9-8]:focus {
  color: var(--color--9-8);
}

[class*=sc-f9-8]:focus {
  -webkit-text-stroke-color: var(--color--9-8);
}

[class*=bg-f9-9]:focus {
  background: var(--color--9-9);
}

[class*=bc-f9-9]:focus {
  border-color: var(--color--9-9);
}

[class*=oc-f9-9]:focus {
  outline-color: var(--color--9-9);
}

[class*=fc-f9-9]:focus {
  color: var(--color--9-9);
}

[class*=sc-f9-9]:focus {
  -webkit-text-stroke-color: var(--color--9-9);
}

[class*=ht50] {
  transition-property: all;
  transition-duration: 50ms;
}

[class*=ht100] {
  transition-property: all;
  transition-duration: 100ms;
}

[class*=ht150] {
  transition-property: all;
  transition-duration: 150ms;
}

[class*=ht200] {
  transition-property: all;
  transition-duration: 200ms;
}

[class*=ht250] {
  transition-property: all;
  transition-duration: 250ms;
}

[class*=ht300] {
  transition-property: all;
  transition-duration: 300ms;
}

[class*=ht350] {
  transition-property: all;
  transition-duration: 350ms;
}

[class*=ht400] {
  transition-property: all;
  transition-duration: 400ms;
}

[class*=ht450] {
  transition-property: all;
  transition-duration: 450ms;
}

[class*=ht500] {
  transition-property: all;
  transition-duration: 500ms;
}

[class*=xs_ed0] {
  display: none;
}

[class*=xs_ed1] {
  display: block;
}

[class*=xs_ed2] {
  display: flex;
}

[class*=xs_ed3] {
  display: grid;
}

[class*=xs_ed4] {
  display: inline-block;
}

[class*=xs_ed5] {
  display: inline;
}

[class*=xs_ed6] {
  display: inline-flex;
}

[class*=xs_ep0] {
  position: static;
}

[class*=xs_ep1] {
  position: relative;
}

[class*=xs_ep2] {
  position: absolute;
}

[class*=xs_ep3] {
  position: fixed;
}

[class*=xs_ep4] {
  position: sticky;
}

[class*=xs_of0] {
  overflow: visible;
}

[class*=xs_of1] {
  overflow: scroll;
}

[class*=xs_of2] {
  overflow: auto;
}

[class*=xs_of3] {
  overflow: hidden;
}

[class*=xs_ox0] {
  overflow-x: visible;
}

[class*=xs_ox1] {
  overflow-x: scroll;
}

[class*=xs_ox2] {
  overflow-x: auto;
}

[class*=xs_ox3] {
  overflow-x: hidden;
}

[class*=xs_oy0] {
  overflow-y: visible;
}

[class*=xs_oy1] {
  overflow-y: scroll;
}

[class*=xs_oy2] {
  overflow-y: auto;
}

[class*=xs_oy3] {
  overflow-y: hidden;
}

@media (min-width: 480px) {
  [class*=sm_ed0] {
    display: none;
  }
  [class*=sm_ed1] {
    display: block;
  }
  [class*=sm_ed2] {
    display: flex;
  }
  [class*=sm_ed3] {
    display: grid;
  }
  [class*=sm_ed4] {
    display: inline-block;
  }
  [class*=sm_ed5] {
    display: inline;
  }
  [class*=sm_ed6] {
    display: inline-flex;
  }
  [class*=sm_ep0] {
    position: static;
  }
  [class*=sm_ep1] {
    position: relative;
  }
  [class*=sm_ep2] {
    position: absolute;
  }
  [class*=sm_ep3] {
    position: fixed;
  }
  [class*=sm_ep4] {
    position: sticky;
  }
  [class*=sm_of0] {
    overflow: visible;
  }
  [class*=sm_of1] {
    overflow: scroll;
  }
  [class*=sm_of2] {
    overflow: auto;
  }
  [class*=sm_of3] {
    overflow: hidden;
  }
  [class*=sm_ox0] {
    overflow-x: visible;
  }
  [class*=sm_ox1] {
    overflow-x: scroll;
  }
  [class*=sm_ox2] {
    overflow-x: auto;
  }
  [class*=sm_ox3] {
    overflow-x: hidden;
  }
  [class*=sm_oy0] {
    overflow-y: visible;
  }
  [class*=sm_oy1] {
    overflow-y: scroll;
  }
  [class*=sm_oy2] {
    overflow-y: auto;
  }
  [class*=sm_oy3] {
    overflow-y: hidden;
  }
}
@media (min-width: 768px) {
  [class*=md_ed0] {
    display: none;
  }
  [class*=md_ed1] {
    display: block;
  }
  [class*=md_ed2] {
    display: flex;
  }
  [class*=md_ed3] {
    display: grid;
  }
  [class*=md_ed4] {
    display: inline-block;
  }
  [class*=md_ed5] {
    display: inline;
  }
  [class*=md_ed6] {
    display: inline-flex;
  }
  [class*=md_ep0] {
    position: static;
  }
  [class*=md_ep1] {
    position: relative;
  }
  [class*=md_ep2] {
    position: absolute;
  }
  [class*=md_ep3] {
    position: fixed;
  }
  [class*=md_ep4] {
    position: sticky;
  }
  [class*=md_of0] {
    overflow: visible;
  }
  [class*=md_of1] {
    overflow: scroll;
  }
  [class*=md_of2] {
    overflow: auto;
  }
  [class*=md_of3] {
    overflow: hidden;
  }
  [class*=md_ox0] {
    overflow-x: visible;
  }
  [class*=md_ox1] {
    overflow-x: scroll;
  }
  [class*=md_ox2] {
    overflow-x: auto;
  }
  [class*=md_ox3] {
    overflow-x: hidden;
  }
  [class*=md_oy0] {
    overflow-y: visible;
  }
  [class*=md_oy1] {
    overflow-y: scroll;
  }
  [class*=md_oy2] {
    overflow-y: auto;
  }
  [class*=md_oy3] {
    overflow-y: hidden;
  }
}
@media (min-width: 992px) {
  [class*=lg_ed0] {
    display: none;
  }
  [class*=lg_ed1] {
    display: block;
  }
  [class*=lg_ed2] {
    display: flex;
  }
  [class*=lg_ed3] {
    display: grid;
  }
  [class*=lg_ed4] {
    display: inline-block;
  }
  [class*=lg_ed5] {
    display: inline;
  }
  [class*=lg_ed6] {
    display: inline-flex;
  }
  [class*=lg_ep0] {
    position: static;
  }
  [class*=lg_ep1] {
    position: relative;
  }
  [class*=lg_ep2] {
    position: absolute;
  }
  [class*=lg_ep3] {
    position: fixed;
  }
  [class*=lg_ep4] {
    position: sticky;
  }
  [class*=lg_of0] {
    overflow: visible;
  }
  [class*=lg_of1] {
    overflow: scroll;
  }
  [class*=lg_of2] {
    overflow: auto;
  }
  [class*=lg_of3] {
    overflow: hidden;
  }
  [class*=lg_ox0] {
    overflow-x: visible;
  }
  [class*=lg_ox1] {
    overflow-x: scroll;
  }
  [class*=lg_ox2] {
    overflow-x: auto;
  }
  [class*=lg_ox3] {
    overflow-x: hidden;
  }
  [class*=lg_oy0] {
    overflow-y: visible;
  }
  [class*=lg_oy1] {
    overflow-y: scroll;
  }
  [class*=lg_oy2] {
    overflow-y: auto;
  }
  [class*=lg_oy3] {
    overflow-y: hidden;
  }
}
[class*="--x0"] {
  font-size: var(--font--x0);
}

[class*="--x1"] {
  font-size: var(--font--x1);
}

[class*="--x2"] {
  font-size: var(--font--x2);
}

[class*="--x3"] {
  font-size: var(--font--x3);
}

[class*="--x4"] {
  font-size: var(--font--x4);
}

[class*="--x5"] {
  font-size: var(--font--x5);
}

[class*="--x6"] {
  font-size: var(--font--x6);
}

[class*="--x7"] {
  font-size: var(--font--x7);
}

[class*="--x8"] {
  font-size: var(--font--x8);
}

[class*="--x9"] {
  font-size: var(--font--x9);
}

[class*="--x10"] {
  font-size: var(--font--x10);
}

[class*="--x11"] {
  font-size: var(--font--x11);
}

[class*="--x12"] {
  font-size: var(--font--x12);
}

[class*="--x13"] {
  font-size: var(--font--x13);
}

[class*="--x14"] {
  font-size: var(--font--x14);
}

[class*="--x15"] {
  font-size: var(--font--x15);
}

[class*="--x16"] {
  font-size: var(--font--x16);
}

[class*="--x17"] {
  font-size: var(--font--x17);
}

[class*="--x18"] {
  font-size: var(--font--x18);
}

[class*="--x19"] {
  font-size: var(--font--x19);
}

[class*=tw0] {
  font-weight: var(--font-weight--0);
}

[class*=tw1] {
  font-weight: var(--font-weight--1);
}

[class*=tw2] {
  font-weight: var(--font-weight--2);
}

[class*=tw3] {
  font-weight: var(--font-weight--3);
}

[class*=tw4] {
  font-weight: var(--font-weight--4);
}

[class*=tw5] {
  font-weight: var(--font-weight--5);
}

[class*=tw6] {
  font-weight: var(--font-weight--6);
}

[class*=tw7] {
  font-weight: var(--font-weight--7);
}

[class*=tw8] {
  font-weight: var(--font-weight--8);
}

[class*=tw9] {
  font-weight: var(--font-weight--9);
}

[class*=th0] {
  line-height: var(--font-height--0);
}

[class*=th1] {
  line-height: var(--font-height--1);
}

[class*=th2] {
  line-height: var(--font-height--2);
}

[class*=th3] {
  line-height: var(--font-height--3);
}

[class*=th4] {
  line-height: var(--font-height--4);
}

[class*=th5] {
  line-height: var(--font-height--5);
}

[class*=th6] {
  line-height: var(--font-height--6);
}

[class*=th7] {
  line-height: var(--font-height--7);
}

[class*=th8] {
  line-height: var(--font-height--8);
}

[class*=th9] {
  line-height: var(--font-height--9);
}

[class*=ts0] {
  letter-spacing: var(--font-spacing--0);
}

[class*=ts1] {
  letter-spacing: var(--font-spacing--1);
}

[class*=ts2] {
  letter-spacing: var(--font-spacing--2);
}

[class*=ts3] {
  letter-spacing: var(--font-spacing--3);
}

[class*=ts4] {
  letter-spacing: var(--font-spacing--4);
}

[class*=ts5] {
  letter-spacing: var(--font-spacing--5);
}

[class*=ts6] {
  letter-spacing: var(--font-spacing--6);
}

[class*=ts7] {
  letter-spacing: var(--font-spacing--7);
}

[class*=ts8] {
  letter-spacing: var(--font-spacing--8);
}

[class*=ts9] {
  letter-spacing: var(--font-spacing--9);
}

[class*=ff0] {
  font-family: var(--font-family--0);
}

[class*=ff1] {
  font-family: var(--font-family--1);
}

[class*=ff2] {
  font-family: var(--font-family--2);
}

[class*=ff3] {
  font-family: var(--font-family--3);
}

[class*=ff4] {
  font-family: var(--font-family--4);
}

[class*=ff5] {
  font-family: var(--font-family--5);
}

[class*=ff6] {
  font-family: var(--font-family--6);
}

[class*=ff7] {
  font-family: var(--font-family--7);
}

[class*=ff8] {
  font-family: var(--font-family--8);
}

[class*=ff9] {
  font-family: var(--font-family--9);
}

[class*=tt0] {
  text-transform: lowercase;
}

[class*=tt1] {
  text-transform: capitalize;
}

[class*=tt2] {
  text-transform: uppercase;
}

[class*=xs_ta0] {
  text-align: left;
}

[class*=xs_ta1] {
  text-align: center;
}

[class*=xs_ta2] {
  text-align: right;
}

[class*=xs_ta3] {
  text-align: justify;
}

[class*=xs_ta4] {
  text-align: start;
}

[class*=xs_ta5] {
  text-align: end;
}

@media (min-width: 480px) {
  [class*=sm_ta0] {
    text-align: left;
  }
  [class*=sm_ta1] {
    text-align: center;
  }
  [class*=sm_ta2] {
    text-align: right;
  }
  [class*=sm_ta3] {
    text-align: justify;
  }
  [class*=sm_ta4] {
    text-align: start;
  }
  [class*=sm_ta5] {
    text-align: end;
  }
}
@media (min-width: 768px) {
  [class*=md_ta0] {
    text-align: left;
  }
  [class*=md_ta1] {
    text-align: center;
  }
  [class*=md_ta2] {
    text-align: right;
  }
  [class*=md_ta3] {
    text-align: justify;
  }
  [class*=md_ta4] {
    text-align: start;
  }
  [class*=md_ta5] {
    text-align: end;
  }
}
@media (min-width: 992px) {
  [class*=lg_ta0] {
    text-align: left;
  }
  [class*=lg_ta1] {
    text-align: center;
  }
  [class*=lg_ta2] {
    text-align: right;
  }
  [class*=lg_ta3] {
    text-align: justify;
  }
  [class*=lg_ta4] {
    text-align: start;
  }
  [class*=lg_ta5] {
    text-align: end;
  }
}